import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Globals } from './globals';
import { MatDialog } from '@angular/material';
import { DefaultDialogComponent } from 'app/dialogs/default-dialog/default-dialog.component';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(
    private router: Router,
    private global: Globals,
    private _fuseNavigationService: FuseNavigationService,
    private dialog: MatDialog
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.global.isLoggedIn()) {
      const customFunctionNavItem = {
        id: 'logout-function',
        title: '로그아웃',
        type: 'group',
        icon: 'settings',
        children: [
          {
            id: 'logout',
            title: '로그아웃',
            type: 'item',
            function: () => {
              this.logout();
            }
          }
        ]
      }
      if(!this._fuseNavigationService.getNavigationItem(customFunctionNavItem.id)){
        this._fuseNavigationService.addNavigationItem(customFunctionNavItem, 'end');
      }
      return true
    } else {
      this.router.navigate(['login'])
      return false
    }
  }

  logout(): void {
    const dialogRef = this.dialog.open(DefaultDialogComponent)
    dialogRef.componentInstance.data = { content: "로그아웃하시겠습니까?", activeText: "예", closeText: '닫기' }
    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        this._fuseNavigationService.removeNavigationItem('logout-function')
        this.global.logOut()
        this.router.navigate(["login"])
      }
    })
  }
}