import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, ValidatorFn, ValidationErrors, Validators, FormControl } from '@angular/forms';
import { APIService } from 'app/services/api.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ModifyPasswordDialogComponent } from 'app/dialogs/modify-password-dialog/modify-password-dialog.component';
import { read } from 'fs';
import { Globals } from 'app/services/globals';
import { zip } from 'rxjs';
import { DefaultDialogComponent } from 'app/dialogs/default-dialog/default-dialog.component';
import * as _ from 'underscore';

@Component({
  selector: 'app-member-detail',
  templateUrl: './member-detail.component.html',
  styleUrls: ['./member-detail.component.scss']
})
export class MemberDetailComponent implements OnInit {
  id: string
  isGradeShow = false
  selectedImage
  selectedFile
  form: FormGroup = this.formBuilder.group({
    auth_code: '',
    id: '',
    password: '',
    password_reinput: '',
    name: '',
    nick: '',
    position: '',
    phone: '',
    email: '',
    file_name: '',
    profile_link_pc: "",
    profile_link_pc_content: "",
    profile_link_pc_check: false,
    profile_link_mobile: "",
    profile_link_mobile_content: "",
    profile_link_mobile_check: false,
    profile_image_key: '',
    zip_code: '',
    address: {zip_code: "", address: "", detail_address: ""},
    address1: '',
    address1_1: '',//읽기모드일 때 사용
    address2: '',
    etc_field_1: '',
    etc_field_2: '',
    etc_field_3: '',
    status: 'normal',
    is_decoy: false
  }, { validator: passwordMatchValidator })
  mode: string
  setting: any
  grades = []

  constructor(public api: APIService,
    public dialog: MatDialog,
    private global: Globals,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private router: Router) {
      this.mode = this.route.snapshot.queryParams['mode'] || 'info';
      this.id = this.route.snapshot.params['id']

      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      };
    }

  ngOnInit() {
    this.isGradeShow = this.global.user.auth >= AUTH.MANAGER

    this.getGrades()
    this.getSetting()

    if(this.mode == "info" || this.mode == "modify"){
      this.getInfo()
    }
  }

  getGrades(){
    this.api.getGrades().subscribe(response => {
      this.grades = response
      let defaultGrade = _.find(response, (grade)=>{ return grade.select == true })
      if(defaultGrade){
        this.form.patchValue({auth_code: defaultGrade.code})
      }
    })
  }

  getSetting(){
    this.api.getUserSetting().subscribe(response => {
      this.setting = response
    })
  }
  validateObject(obj, arr) {
    let ret = _.property(arr)(obj)
    return _.isUndefined(ret) ? '' : ret
  }

  setInfo(data) {
    if (data) {
      console.log(data)
      if (this.mode == "info") {
        this.form = this.formBuilder.group({
          auth_code: data.auth_code,
          id: data.id,
          name: data.name,
          nick: data.nick,
          position: this.validateObject(data, ['info', 'position']),
          phone: data.phone,
          email: data.email,
          profile_link_pc_content: this.validateObject(data, ['info', 'profile_link_pc', 'content']),
          profile_link_pc_check: this.validateObject(data, ['info', 'profile_link_pc', 'new_window']),
          profile_link_mobile_content: this.validateObject(data, ['info', 'profile_link_mobile', 'content']),
          profile_link_mobile_check: this.validateObject(data, ['info', 'profile_link_mobile', 'new_window']),
          profile_image_key: this.validateObject(data, ['info', 'profile_image_key']),
          file_name: this.validateObject(data, ['info', 'file_name']),
          zip_code: this.validateObject(data, ['info', 'address', 'zip_code']),
          address1: this.validateObject(data, ['info', 'address', 'address']),
          address1_1: this.validateObject(data, ['info', 'address', 'address']),//읽기모드일 때 사용
          address2: this.validateObject(data, ['info', 'address', 'detail_address']),
          etc_field_1: this.validateObject(data, ['info', 'etc_field_1']),
          etc_field_2: this.validateObject(data, ['info', 'etc_field_2']),
          etc_field_3: this.validateObject(data, ['info', 'etc_field_3']),
          status: data.status,
          is_decoy: data.is_decoy
        })

        setTimeout(() => {
          this.form.controls.auth_code.disable()
          this.form.controls.status.disable()
        })
      } else if (this.mode == "modify") {
        this.form = this.formBuilder.group({
          auth_code: data.auth_code,
          id: data.id,
          name: data.name,
          nick: data.nick,
          position: this.validateObject(data, ['info', 'position']),
          phone: data.phone,
          address:
          {
            zip_code: this.validateObject(data, ['info', 'address', 'zip_code']),
            address: this.validateObject(data, ['info', 'address', 'address']),
            detail_address: this.validateObject(data, ['info', 'address', 'detail_address'])
          },
          profile_link_pc: {},
          profile_link_mobile: {},
          email: data.email,
          profile_link_pc_content: this.validateObject(data, ['info', 'profile_link_pc', 'content']),
          profile_link_pc_check: this.validateObject(data, ['info', 'profile_link_pc', 'new_window']),
          profile_link_mobile_content: this.validateObject(data, ['info', 'profile_link_mobile', 'content']),
          profile_link_mobile_check: this.validateObject(data, ['info', 'profile_link_mobile', 'new_window']),
          profile_image_key: this.validateObject(data, ['info', 'profile_image_key']),
          file_name: this.validateObject(data, ['info', 'file_name']),
          zip_code: this.validateObject(data, ['info', 'address', 'zip_code']),
          address1: this.validateObject(data, ['info', 'address', 'address']),
          address2: this.validateObject(data, ['info', 'address', 'detail_address']),
          etc_field_1: this.validateObject(data, ['info', 'etc_field_1']),
          etc_field_2: this.validateObject(data, ['info', 'etc_field_2']),
          etc_field_3: this.validateObject(data, ['info', 'etc_field_3']),
          status: data.status,
          is_decoy: data.is_decoy
        })

        if(this.global.user.auth < AUTH.MANAGER){
          setTimeout(() => {
            this.form.controls.auth_code.disable()
          })
        }
      }
    }
  }

  getInfo(){
    this.api.getUser(this.id).subscribe(response => {
      this.setInfo(response)
    })
  }

  async save(){
    if(this.selectedFile){
      let response = await this.api.uploadFile(this.selectedFile, "user")
      this.form.patchValue({profile_image_key: response.key})
    }
    
    if(this.form.value.address1.length > 0){
      if(this.form.contains("address")){
        this.form.patchValue({address: {zip_code: this.form.value.zip_code, address: this.form.value.address1, detail_address: this.form.value.address2 }})
      }else{
        this.form.addControl("address", new FormControl({zip_code: this.form.value.zip_code, address: this.form.value.address1, detail_address: this.form.value.address2 }))
      }
    }else{
      this.form.removeControl('address')
    }
    if(this.form.value.profile_link_pc_content.length > 0){
      if(this.form.contains("profile_link_pc")){
        this.form.patchValue({profile_link_pc: {new_window: this.form.value.profile_link_pc_check, content: this.form.value.profile_link_pc_content}})
      }else{
        this.form.addControl("profile_link_pc", new FormControl({new_window: this.form.value.profile_link_pc_check, content: this.form.value.profile_link_pc_content}))
      }
    }else{
      this.form.removeControl('profile_link_pc')
    }
    if(this.form.value.profile_link_mobile_content.length > 0){
      if(this.form.contains("profile_link_mobile")){
        this.form.patchValue({profile_link_mobile: {new_window: this.form.value.profile_link_mobile_check, content: this.form.value.profile_link_mobile_content}})
      }else{
        this.form.addControl("profile_link_mobile", new FormControl({new_window: this.form.value.profile_link_mobile_check, content: this.form.value.profile_link_mobile_content}))
      }
    }else{
      this.form.removeControl('profile_link_mobile')
    }

    //공백인 폼 컨트롤러는 제거
    let body = _.pick(this.form.value, function(value, key, object){
      if(typeof value !== "string"){
        return true
      }else{
        return value.length > 0
      }
    })
    //console.log(body)

    if(this.mode == "reg"){
      this.api.regUser(body).subscribe(response => {
        const dialogRef = this.dialog.open(DefaultDialogComponent)
        dialogRef.componentInstance.data = { content: '등록되었습니다.', closeText: '확인' }
        dialogRef.afterClosed().subscribe(response => {
          this.router.navigate(['/member'])
        })
      })
    }else{
      this.api.modifyUser(this.id, body).subscribe(response => {
        const dialogRef = this.dialog.open(DefaultDialogComponent)
        dialogRef.componentInstance.data = { content: '저장되었습니다.', closeText: '확인' }
        dialogRef.afterClosed().subscribe(response => {
          this.router.navigate(['/member',  this.id], { queryParams: { mode: 'info' } })
        })
      })
    }
  }

  mod(){
    this.router.navigate(['/member', this.form.value.id], { queryParams: { mode: 'modify' } })
  }

  modifyPassword(){
    let dialogRef = this.dialog.open(ModifyPasswordDialogComponent)
    dialogRef.componentInstance.data = { id: this.form.value.id }
  }

  goList(){
    this.router.navigate(["/member"])
  }

  onChangeAddr(addr, zip_code) {
    this.form.patchValue({address1: addr})
    this.form.patchValue({zip_code: zip_code})
  }

  get password() { return this.form.get('password'); }
  get password_reinput() { return this.form.get('password_reinput'); }

  onPasswordInput() {
    if (this.form.hasError('passwordMismatch'))
      this.password_reinput.setErrors([{'passwordMismatch': true}])
    else if(this.password_reinput){
      this.password_reinput.setErrors(null)
    }
  }

  onFilesAdded(file) {
    if (file && file[0]) {
      file = file[0]

      let reader = new FileReader();
      reader.onload = () => {
        var img = new Image()
        img.onload = () => {
          this.selectedFile = file
          this.selectedImage = img
          this.form.patchValue({file_name: file.name})
          
          // if(img.width == 500 && img.height == 500){
          //   this.selectedFile = file
          //   this.selectedImage = img
          //   this.form.patchValue({file_name: file.name})
          // }else{
          //   this.snackBar.open("이미지 크기를 확인해주세요(500px * 500px)", "확인", {
          //     duration: 3000,
          //   })
          // }
        }
        if(typeof reader.result === 'string'){
          img.src = reader.result
        }
      }
      reader.readAsDataURL(file);
    }
  }
  
  deleteImage() {
    this.selectedFile = undefined
    this.selectedImage = undefined
    this.form.patchValue({profile_image_key: undefined})
    this.form.patchValue({file_name: undefined})
  }
}

export const passwordMatchValidator: ValidatorFn = (formGroup: FormGroup): ValidationErrors | null => {
  if (formGroup.get('password').value === formGroup.get('password_reinput').value)
    return null;
  else
    return {passwordMismatch: true};
}