import { Injectable, Renderer2 } from '@angular/core';
import { AuthService } from './auth.service';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Globals } from './globals';
import { MatDialog } from '@angular/material';
import { DefaultDialogComponent } from 'app/dialogs/default-dialog/default-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

    constructor(
        private auth: AuthService,
        public global: Globals,
        private dialog: MatDialog,
        protected router: Router) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.auth.getToken() != null) {
            request = request.clone({
                setHeaders: {
                    "x-auth-token": this.auth.getToken()
                }
            });
        }
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                }

                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.status == 401 && this.router.url != '/login') {
                    this.global.logOut()
                    this.router.navigate(['login'])
                } else {
                    if (error.error && error.error.message && request.headers.get('Error-Handle') != 'self') {
                        setTimeout(async () => {
                            let dialogRef = this.dialog.open(DefaultDialogComponent)
                            dialogRef.componentInstance.data = { content: error.error.message, closeText: '확인' }
                        })
                    }
                }
                return throwError(error)
            }));
    }
}
