import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { DateAdapter, NativeDateAdapter } from '@angular/material/core';

import * as _moment from 'moment';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';



export class MyDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: Object): string {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    return year + '-' + this._to2digit(month) + '-' + this._to2digit(day)
  }

  private _to2digit(n: number) {
    return ('00' + n).slice(-2);
  }
}

const moment = _moment
export const MY_FORMATS = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' }
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' }
  }
};
@Component({
  selector: 'app-search-date',
  templateUrl: './search-date.component.html',
  styleUrls: ['./search-date.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MyDateAdapter },
    { provide: MY_FORMATS, useValue: MY_FORMATS },
  ],
})
export class SearchDateComponent implements OnInit {
  @Input() title
  @Output() searchEvent = new EventEmitter<any>();
  form: FormGroup;
  constructor(
    private _formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    let endDate = ''
    let startDate = ''
    // Reactive Form
    this.form = this._formBuilder.group({
      startDate: [startDate, Validators.required],
      endDate: [endDate, Validators.required]
    })
    this.searchEvent.emit(this.form.value)
  }

  StartDateChange(date) {
    this.form.controls["startDate"].setValue(moment(date).format('YYYY-MM-DD'))
    this.searchEvent.emit(this.form.value)
  }

  EndDateChange(date) {
    this.form.controls["endDate"].setValue(moment(date).format('YYYY-MM-DD'))
    this.searchEvent.emit(this.form.value)
  }

  yesterday() {
    this.form.controls["startDate"].setValue(moment().subtract('days', 1).format('YYYY-MM-DD'))
    this.form.controls["endDate"].setValue(moment().subtract('days', 1).format('YYYY-MM-DD'))
    this.searchEvent.emit(this.form.value)
  }
  today() {
    this.form.controls["startDate"].setValue(moment().format('YYYY-MM-DD'))
    this.form.controls["endDate"].setValue(moment().format('YYYY-MM-DD'))
    this.searchEvent.emit(this.form.value)
  }
  week() {
    this.form.controls["startDate"].setValue(moment().subtract('days', 7).format('YYYY-MM-DD'))
    this.form.controls["endDate"].setValue(moment().format('YYYY-MM-DD'))
    this.searchEvent.emit(this.form.value)
  }
  twoWeek(){
    this.form.controls["startDate"].setValue(moment().subtract('days', 14).format('YYYY-MM-DD'))
    this.form.controls["endDate"].setValue(moment().format('YYYY-MM-DD'))
    this.searchEvent.emit(this.form.value)
  }
  month() {
    this.form.controls["startDate"].setValue(moment().subtract('month', 1).format('YYYY-MM-DD'))
    this.form.controls["endDate"].setValue(moment().format('YYYY-MM-DD'))
    this.searchEvent.emit(this.form.value)
  }
  twoMonth(){
    this.form.controls["startDate"].setValue(moment().subtract('month', 2).format('YYYY-MM-DD'))
    this.form.controls["endDate"].setValue(moment().format('YYYY-MM-DD'))
    this.searchEvent.emit(this.form.value)
  }
  year() {
    this.form.controls["startDate"].setValue(moment().subtract('years', 1).format('YYYY-MM-DD'))
    this.form.controls["endDate"].setValue(moment().format('YYYY-MM-DD'))
    this.searchEvent.emit(this.form.value)
  }

}
