import { Environment } from 'app/Environment';
import { Injectable } from '@angular/core';
import * as JWT from 'jwt-decode'
import { User } from 'app/models/user';

@Injectable({
    providedIn: 'root'
  })
export class Globals {
    public BACKEND_HOST:string = Environment.BACKEND_HOST
    isLogOn: boolean = false
    isHomePage: boolean = false
    isMobile: boolean = false
    user: User = new User()
    froalaKey : string = "cB10C9C5D5C-11A2B2C2H2C9B3C4C6D2A1wCHCPa1XQVZFSHSa1C=="

    logIn(token){
        localStorage.setItem('id_token', token);
        this.isLogOn = true
    }

    logOut() {
        localStorage.removeItem("id_token");
        this.isLogOn = false
    }

    setUser(user){
        this.user.id = user.id
        this.user.name = user.name
        this.user.nick = user.nick
        this.user.email = user.email
        this.user.status = user.status
        this.user.auth = this.authToNumber(user.auth_code)
    }

    authToNumber(auth_code): number{
        if(auth_code == "ADMIN"){
            return AUTH.ADMIN
        }else if(auth_code == "MANAGER"){
            return AUTH.MANAGER
        }else if(auth_code == "LAWYER"){
            return AUTH.LAWYER
        }else if(auth_code == "USER"){
            return AUTH.USER
        }
    }
    
    public isLoggedIn(): boolean {
        var result: boolean = false
        var token: string = localStorage.getItem('id_token')
        
        if(token != null && token != undefined && token.length > 0){
            let userData: any = JWT(token);
            
            this.user.id = userData.id
            this.user.name = userData.name
            this.user.auth = this.authToNumber(userData.scopes[0])

            this.isLogOn = true
            result = true
        }else{
            this.isLogOn = false
        }
        return result
    }
}