import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { APIService } from 'app/services/api.service';
import { MatDialog, MatSnackBar, MatIconRegistry } from '@angular/material';
import { Globals } from 'app/services/globals';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import * as _ from 'underscore';
import { DefaultDialogComponent } from 'app/dialogs/default-dialog/default-dialog.component';

@Component({
  selector: 'app-signup-management',
  templateUrl: './signup-management.component.html',
  styleUrls: ['./signup-management.component.scss']
})
export class SignupManagementComponent implements OnInit {
  form: FormGroup = this.formBuilder.group({
    member_grade: 'use',
    require_member_grade: false,
    id: 'use',
    pw: 'use',
    pw2: 'use',
    name: 'use',
    require_name: false,
    nick: 'use',
    require_nick: false,
    position: 'use',
    require_position: false,
    phone: 'use',
    require_phone: false,
    agree_phone: false,
    email: 'use',
    require_email: false,
    agree_email: false,
    profile_link_pc: 'use',
    require_profile_link_pc: false,
    profile_link_mobile: 'use',
    require_profile_link_mobile: false,
    profile_image: 'use',
    require_profile_image: false,
    address: 'use',
    require_address: false,
    extra1: 'use',
    require_extra1: false,
    input_extra1: '',
    extra2: 'use',
    require_extra2: false,
    input_extra2: '',
    extra3: 'use',
    require_extra3: false,
    input_extra3: '',
  })
  memberGrades = []

  constructor(public api: APIService,
    public dialog: MatDialog,
    private global: Globals,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private router: Router) { }

  ngOnInit() {
    this.getSetting()
  }

  getSetting() {
    this.api.getUserSetting().subscribe(response => {
      this.form = this.formBuilder.group({
        member_grade: response.auth_level.use ? 'use' : 'notuse',
        require_member_grade: response.auth_level.mandatory,
        id: 'use',
        pw: 'use',
        pw2: response.password_reinput ? 'use' : 'notuse',
        name: response.name.use ? 'use' : 'notuse',
        require_name: response.name.mandatory,
        nick: response.nick.use ? 'use' : 'notuse',
        require_nick: response.nick.mandatory,
        position: response.position.use ? 'use' : 'notuse',
        require_position: response.position.mandatory,
        phone: response.phone.use ? 'use' : 'notuse',
        require_phone: response.phone.mandatory,
        agree_phone: response.phone.use_info_agree,
        email: response.email.use ? 'use' : 'notuse',
        require_email: response.email.mandatory,
        agree_email: response.email.use_info_agree,
        profile_link_pc: response.profile_link_pc.use ? 'use' : 'notuse',
        require_profile_link_pc: response.profile_link_pc.mandatory,
        profile_link_mobile: response.profile_link_mobile.use ? 'use' : 'notuse',
        require_profile_link_mobile: response.profile_link_mobile.mandatory,
        profile_image: response.profile_image.use ? 'use' : 'notuse',
        require_profile_image: response.profile_image.mandatory,
        address: response.address.use ? 'use' : 'notuse',
        require_address: response.address.mandatory,
        extra1: response.etc_field_1.use ? 'use' : 'notuse',
        require_extra1: response.etc_field_1.mandatory,
        input_extra1: response.etc_field_1.label,
        extra2: response.etc_field_2.use ? 'use' : 'notuse',
        require_extra2: response.etc_field_2.mandatory,
        input_extra2: response.etc_field_2.label,
        extra3: response.etc_field_3.use ? 'use' : 'notuse',
        require_extra3: response.etc_field_3.mandatory,
        input_extra3: response.etc_field_3.label,
      })
    })
  }

  addMemberGrade(name, check) {
    if (name.length == 0) {
      this.snackBar.open("등급을 입력해주세요.", "확인", {
        duration: 2000,
      })
    } else {
      if (_.every(this.memberGrades, function (member) { return member.name != name; })) {
        let body = { name: name, access_admin_site: check }
        this.api.regGrade(body).subscribe(response => {
          this.memberGrades.push(response)
        })
      } else {
        this.snackBar.open("이미 등록되었습니다.", "확인", {
          duration: 2000,
        })
      }
    }
  }

  delMemberGrade(member) {
    if(member.code){
      this.api.delGrade(member.code).subscribe(response => {
        this.memberGrades = _.filter(this.memberGrades, function (m) {
          return m.name != member.name
        })
      })
    }
  }

  save() {
    let body = {
      address: { use: this.form.value.address == 'use' ? true : false, label: "주소", mandatory: this.form.value.require_address },
      auth_level: { use: this.form.value.member_grade == 'use' ? true : false, label: "회원등급", mandatory: this.form.value.require_member_grade },
      email: { use: this.form.value.email == 'use' ? true : false, label: "이메일", mandatory: this.form.value.require_email, use_info_agree: this.form.value.agree_email },
      etc_field_1: { use: this.form.value.extra1 == 'use' ? true : false, label: this.form.value.input_extra1, mandatory: this.form.value.require_extra1 },
      etc_field_2: { use: this.form.value.extra2 == 'use' ? true : false, label: this.form.value.input_extra2, mandatory: this.form.value.require_extra2 },
      etc_field_3: { use: this.form.value.extra3 == 'use' ? true : false, label: this.form.value.input_extra3, mandatory: this.form.value.require_extra3 },
      use_id: true,
      name: { use: this.form.value.name == 'use' ? true : false, label: "이름", mandatory: this.form.value.require_name },
      nick: { use: this.form.value.nick == 'use' ? true : false, label: "닉네임", mandatory: this.form.value.require_nick },
      use_password: true,
      use_password_reinput: this.form.value.pw2 == 'use' ? true : false,
      phone: { use: this.form.value.phone == 'use' ? true : false, label: "연락처", mandatory: this.form.value.require_phone, use_info_agree: this.form.value.agree_phone },
      position: { use: this.form.value.position == 'use' ? true : false, label: "직책", mandatory: this.form.value.require_position },
      profile_image: { use: this.form.value.profile_image == 'use' ? true : false, label: "프로필 이미지", mandatory: this.form.value.require_profile_image },
      profile_link_pc: { use: this.form.value.profile_link_pc == 'use' ? true : false, label: "프로필 링크 PC", mandatory: this.form.value.require_profile_link_pc },
      profile_link_mobile: { use: this.form.value.profile_link_mobile == 'use' ? true : false, label: "프로필 링크 Mobile", mandatory: this.form.value.require_profile_link_mobile },
    }

    this.api.setUserSetting(body).subscribe(response => {
      const dialogRef = this.dialog.open(DefaultDialogComponent)
      dialogRef.componentInstance.data = { content: '저장되었습니다.', closeText: '확인' }
    })
  }
}
