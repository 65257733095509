import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { APIService } from 'app/services/api.service';
import { MatDialog, MatSnackBar, MatIconRegistry } from '@angular/material';
import { Globals } from 'app/services/globals';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { DefaultDialogComponent } from 'app/dialogs/default-dialog/default-dialog.component';
import { SelectionModel } from '@angular/cdk/collections';
import * as _ from 'underscore'

@Component({
  selector: 'app-seo-management',
  templateUrl: './seo-management.component.html',
  styleUrls: ['./seo-management.component.scss']
})
export class SeoManagementComponent implements OnInit {
  form1: FormGroup = this.formBuilder.group({
    title: "",
    author: "",
    desc: "",
    keywords: "",
  })
  form2: FormGroup = this.formBuilder.group({
    og_image_name: "",
    og_image_key: "",
    title: "",
    desc: "",
  })
  form3: FormGroup = this.formBuilder.group({
    sitemap_file_name: "",
    sitemap_file_key: ""
  })
  form4: FormGroup = this.formBuilder.group({
    rss_file_name: "",
    rss_file_key: ""
  })
  form5: FormGroup = this.formBuilder.group({
    url: ""
  })
  selectedImageOG
  selectedFileOG
  selectedFileSiteMap
  selectedFileRSS
  displayedColumns = ['select', 'board_id', 'board_name', 'url_prefix', 'content_count']
  boards
  selection = new SelectionModel(true, [], true);
  sitemapSettings = {
    selected_board_ids: [],
    auto_make: {
      use: true,
      create_at: '02:00'
    },
    additional_manual_urls: "",
    exclude_post_idxs: "",
    exclude_creator_ids: ""
  }
  constructor(public api: APIService,
    public dialog: MatDialog,
    private global: Globals,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private router: Router) { }

  async ngOnInit() {
    let allBoards = await this.api.getBoards(1, 9999).toPromise()
    this.boards = allBoards.data
    this.getSetting()
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.boards ? this.boards.length : 0;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.boards.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  getSetting() {
    this.api.getSEOSetting().subscribe(response => {
      this.form1 = this.formBuilder.group({
        title: response.meta_title,
        author: response.meta_author,
        desc: response.meta_description,
        keywords: response.meta_keywords,
      })

      let ogImageName
      let ogImageKey
      if (!!response.og_image) {
        ogImageName = response.og_image.file_name;
        ogImageKey = response.og_image.key;
      }
      this.form2 = this.formBuilder.group({
        og_image_name: ogImageName,
        og_image_key: ogImageKey,
        title: response.og_title,
        desc: response.og_description,
      })
      this.selection = new SelectionModel(true, [], true);
      _.filter(this.boards, item => { return _.contains(response.sitemap_settings.selected_board_ids, item.id) }).forEach(row => { this.selection.select(row) })
      this.selection.changed.subscribe((select) => {
        this.sitemapSettings.selected_board_ids = _.map(select.source.selected, item => {
          return item.id
        })
      })
      this.sitemapSettings = {
        selected_board_ids: response.sitemap_settings.selected_board_ids,
        auto_make: {
          use: response.sitemap_settings.auto_make.use,
          create_at: response.sitemap_settings.auto_make.create_at
        },
        additional_manual_urls: response.sitemap_settings.additional_manual_urls.join("\n"),
        exclude_post_idxs: response.sitemap_settings.exclude_post_idxs.join(", "),
        exclude_creator_ids: response.sitemap_settings.exclude_creator_ids.join(", ")
      }
      this.form4 = this.formBuilder.group({
        rss_file_name: response.rss_file ? response.rss_file.file_name : null,
        rss_file_key: response.rss_file ? response.rss_file.key : null
      })
      this.form5 = this.formBuilder.group({
        url: response.canonical_url
      })
    })
  }

  async save() {
    let body = {
      meta_title: this.form1.value.title,
      meta_author: this.form1.value.author,
      meta_description: this.form1.value.desc,
      meta_keywords: this.form1.value.keywords,

      og_image_key: this.form2.value.og_image_key,
      og_title: this.form2.value.title,
      og_description: this.form2.value.desc,

      sitemap_file_key: this.form3.value.sitemap_file_key,

      rss_file_key: this.form4.value.rss_file_key,

      canonical_url: this.form5.value.url,

      sitemap_settings: {
        selected_board_ids: this.sitemapSettings.selected_board_ids,
        auto_make: {
          use: this.sitemapSettings.auto_make.use,
          create_at: this.sitemapSettings.auto_make.create_at
        },
        additional_manual_urls: _.map(this.sitemapSettings.additional_manual_urls.split('\n'), item => { return item.trim() }),
        exclude_post_idxs: _.map(this.sitemapSettings.exclude_post_idxs.split(','), item => { return item.trim() }),
        exclude_creator_ids: _.map(this.sitemapSettings.exclude_creator_ids.split(','), item => { return item.trim() })
      }
    }

    if (this.selectedFileOG) {
      let response = await this.api.uploadFile(this.selectedFileOG, "seo")
      this.form2.patchValue({ og_image_key: response.key })
      body.og_image_key = response.key
    }
    if (this.selectedFileSiteMap) {
      let response = await this.api.uploadFile(this.selectedFileSiteMap, "seo")
      this.form3.patchValue({ sitemap_file_key: response.key })
      body.sitemap_file_key = response.key
    }
    if (this.selectedFileRSS) {
      let response = await this.api.uploadFile(this.selectedFileRSS, "seo")
      this.form4.patchValue({ rss_file_key: response.key })
      body.rss_file_key = response.key
    }
    this.api.setSEOSetting(body).subscribe(response => {
      const dialogRef = this.dialog.open(DefaultDialogComponent)
      dialogRef.componentInstance.data = { content: '저장되었습니다.', closeText: '확인' }
    })
  }

  onFilesAddedOG(file) {
    if (file && file[0]) {
      file = file[0]

      let reader = new FileReader();
      reader.onload = () => {
        var img = new Image()
        img.onload = () => {
          this.selectedFileOG = file
          this.selectedImageOG = img
          this.form2.patchValue({ og_image_name: file.name })
        }
        if (typeof reader.result === 'string') {
          img.src = reader.result
        }
      }
      reader.readAsDataURL(file);
    }
  }

  deleteFileOG() {
    this.selectedFileOG = undefined
    this.selectedImageOG = undefined
    this.form2.patchValue({ og_image_name: undefined })
    this.form2.patchValue({ og_image_key: undefined })
  }

  onFilesAddedSiteMap(file) {
    if (file && file[0]) {
      if (file[0].name != "sitemap.xml") {
        this.snackBar.open("sitemap.xml만 등록가능합니다.", "확인", {
          duration: 2000,
        })
      } else {
        this.selectedFileSiteMap = file[0]
        this.form3.patchValue({ sitemap_file_name: file[0].name })
      }
    }
  }

  deleteFileSiteMap() {
    this.selectedFileSiteMap = undefined
    this.form3.patchValue({ sitemap_file_name: undefined })
    this.form3.patchValue({ sitemap_file_key: undefined })
  }

  onFilesAddedRSS(file) {
    if (file && file[0]) {
      if (file[0].name != "rss.xml") {
        this.snackBar.open("rss.xml만 등록가능합니다.", "확인", {
          duration: 2000,
        })
      } else {
        this.selectedFileRSS = file[0]
        this.form4.patchValue({ rss_file_name: file[0].name })
      }
    }
  }

  deleteFileRSS() {
    this.selectedFileRSS = undefined
    this.form4.patchValue({ rss_file_name: undefined })
    this.form4.patchValue({ rss_file_key: undefined })
  }

  async makeSiteMap() {
    await this.api.modifySitemap({
      selected_board_ids: this.sitemapSettings.selected_board_ids,
      auto_make: {
        use: this.sitemapSettings.auto_make.use,
        create_at: this.sitemapSettings.auto_make.create_at
      },
      additional_manual_urls: _.map(this.sitemapSettings.additional_manual_urls.split('\n'), item => { return item.trim() }),
      exclude_post_idxs: _.map(this.sitemapSettings.exclude_post_idxs.split(','), item => { return item.trim() }),
      exclude_creator_ids: _.map(this.sitemapSettings.exclude_creator_ids.split(','), item => { return item.trim() })
    }).toPromise()
    const dialogRef = this.dialog.open(DefaultDialogComponent)
    dialogRef.componentInstance.data = { content: '사이트맵이 생성 되었습니다.', closeText: '확인' }
  }
}
