import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { APIService } from 'app/services/api.service';
import { Globals } from 'app/services/globals';

@Component({
  selector: 'app-normal-banner-detail',
  templateUrl: './normal-banner-detail.component.html',
  styleUrls: ['./normal-banner-detail.component.scss']
})
export class NormalBannerDetailComponent implements OnInit {
  idx: number
  selectedPCImage
  selectedImagePCFile
  selectedImagePCFileKey
  selectedMobileImage
  selectedImageMobileFile
  selectedImageMobileFileKey
  form: FormGroup = this.formBuilder.group({
    title: '',
    content: '',
    image_pc: '',
    image_mobile: '',
    target_url: '',
    target_window: '',
    visible: true
  })
  mode: string
  constructor(public api: APIService,
    public dialog: MatDialog,
    private global: Globals,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private router: Router) { 
      this.mode = this.route.snapshot.queryParams['mode'] || 'info';
      this.idx = this.route.snapshot.queryParams['idx']|| undefined;

      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      };
    }

  ngOnInit() {
    if(this.mode == "info" || this.mode == "modify"){
      this.getInfo()
    }
  }

  setInfo(data){
    if(data){
      this.form = this.formBuilder.group({
        title: data.title,
        content: data.content,
        image_pc: data.image_file_key.file_name,
        image_mobile: data.mobile_image_file_key.file_name,
        target_url: data.target_url,
        target_window: data.target_window,
        visible: data.visible
      })

      if (data.image_file_key) {
        this.selectedImagePCFileKey = data.image_file_key.key
      }

      if (data.mobile_image_file_key) {
        this.selectedImageMobileFileKey = data.mobile_image_file_key.key
      }

      if(this.mode == "info"){
        setTimeout(() => {
          this.form.controls.target_window.disable()
        })
      }
    }
  }

  getInfo(){
    // this.setInfo({title: "슬라이드의 제목입니다.", link: "https://www.bullbyun.com", new_window: "use", order: "2"})
    this.api.getNormalBannerDetail(this.idx).subscribe(response => {
      this.setInfo(response)
    })
  }


  save(){
    let vo = {
      title: this.form.value.title,
      content: this.form.value.content,
      image_file_key: (this.selectedImagePCFileKey? this.selectedImagePCFileKey:''),
      mobile_image_file_key: (this.selectedImageMobileFileKey? this.selectedImageMobileFileKey:''),
      target_url: this.form.value.target_url,
      target_window:this.form.value.target_window,
      visible: this.form.value.visible
    }
    if(this.mode == 'modify'){
      this.api.modifyNormalBanner(this.idx,vo).subscribe(response=>{
        if(response){
          this.router.navigate(['/normal-banner', this.form.value.title], { queryParams: {idx:this.idx, mode: 'info' } })
        }
      })
    }else{
      this.api.createNormalBanner(vo).subscribe(response=>{
        if(response){
          this.router.navigate(['/normal-banner', this.form.value.title], { queryParams: {idx: response.idx, mode: 'info' } })
        }
      })
    }

  }

  mod(){
    //수정
    this.router.navigate(['/normal-banner', this.form.value.title], { queryParams: {idx:this.idx, mode: 'modify' } })
  }

  goList(){
    this.router.navigate(["/normal-banner"])
  }

  
  async onFilesAdded(file, type) {
    if (file && file[0]) {
      file = file[0]

      let response = await this.api.uploadFile(file, "image")
      if(response){
        if(type == "pc"){
          this.selectedImagePCFile = file
          this.selectedImagePCFileKey = response.key
          this.form.controls.image_pc.setValue(file.name)
        }else{
          this.selectedImageMobileFile = file
          this.selectedImageMobileFileKey = response.key
          this.form.controls.image_mobile.setValue(file.name)
        } 
      }
    }
  }
  
  deleteImage(type) {
    if(type == 'pc'){
      this.selectedImagePCFile = undefined
      this.selectedImagePCFileKey = undefined
      this.form.controls.image_pc.setValue("")
    }else{
      this.selectedImageMobileFile = undefined
      this.selectedImageMobileFileKey = undefined
      this.form.controls.image_mobile.setValue("")
    }
  }
}
