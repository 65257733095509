import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id: 'member',
        title: '회원 관리',
        type: 'collapsable',
        children: [
            {
                id: 'member-management',
                title: '회원 관리',
                type: 'item',
                url: '/member/member-management'
            },
        ]
    },
    {
        id: 'popup',
        title: '팝업 관리',
        type: 'collapsable',
        children: [
            {
                id: 'popup-management',
                title: '팝업 레이어 관리',
                type: 'item',
                url: '/popup/popup-layer-management'
            },
        ]
    },
    {
        id: 'banner',
        title: '배너 관리',
        type: 'collapsable',
        children: [
            {
                id: 'slide-banner-management',
                title: '슬라이드 배너 관리',
                type: 'item',
                url: '/banner/slide-banner-management'
            },
            {
                id: 'slide-banner-management',
                title: '배너 관리',
                type: 'item',
                url: '/normal-banner/normal-banner-management'
            },
            {
                id: 'slide-banner-management',
                title: '사이드 배너 관리',
                type: 'item',
                url: '/side-banner/side-banner-management'
            },
        ]
    },
    {
        id: 'board',
        title: '게시판 관리',
        type: 'collapsable',
        children: [
            {
                id: 'board-management',
                title: '게시판 리스트',
                type: 'item',
                url: '/board/board-management'
            },
            {
                id: 'board-content-management',
                title: '게시판 등록',
                type: 'item',
                url: `/board/reg-board`
            }
        ]
    },
    {
        id: 'setting',
        title: '설정',
        type: 'collapsable',
        children: [
            {
                id: 'signup-management',
                title: '회원 가입 항목 관리',
                type: 'item',
                url: '/setting/signup-management'
            },
            {
                id: 'seo-management',
                title: 'SEO 관리',
                type: 'item',
                url: '/setting/seo-management'
            },
            {
                id: 'terms',
                title: '약관/개인정보처리방침',
                type: 'item',
                url: '/setting/terms'
            },
            {
                id:'email',
                title: '이메일 설정 관리',
                type: 'item',
                url: '/setting/email-management'
            },
            {
                id:'template',
                title: '이메일 템플릿 관리',
                type: 'item',
                url: '/setting/email-template-management'
            }
        ]
    }
];
