import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { DefaultDialogComponent } from 'app/dialogs/default-dialog/default-dialog.component';
import { APIService } from 'app/services/api.service';

@Component({
  selector: 'app-normal-banner-management',
  templateUrl: './normal-banner-management.component.html',
  styleUrls: ['./normal-banner-management.component.scss']
})
export class NormalBannerManagementComponent implements OnInit {
  displayedColumns: string[] = ['1', '2', '3','4']
  dataSource: any
  totalCnt
  size: number = 10
  currentPage: number = 1
  constructor(
    public api: APIService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private router: Router,
  ) { }


  ngOnInit() {
    this.getList()
  }

  reg(){
    this.router.navigate(['/normal-banner', 'reg-normal-banner'], { queryParams: { mode: 'reg' } })
  }

  showInfo(data){
    this.router.navigate(['/normal-banner', data.title], { queryParams: {idx:data.idx, mode: 'info' } })
  }

  del(data) {
    const dialogRef = this.dialog.open(DefaultDialogComponent)
    dialogRef.componentInstance.data = { content: '삭제 하시겠습니까?', activeText: '삭제', closeText: '취소' }
    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        this.api.deleteNormalBanner(data.idx).subscribe(response => {
          if (response) {
            this.refresh()
          }
        })
      }
    })
  }

  getList() {
    this.api.getNormalBannerList(this.currentPage,this.size).subscribe(response => {
      for (let property of response.data) {
        property.no = (this.size * (this.currentPage - 1)) + response.data.indexOf(property) + 1
      }
      this.dataSource = new MatTableDataSource(response.data)
    })
  }

  changeVisible(idx, visible) {
    this.api.modifyNormalBanner(idx, { 'visible': visible }).subscribe(response => {
      this.refresh()
    })
  }

  refresh(){
    this.currentPage = 1
    this.dataSource = []
    this.totalCnt = 0

    this.getList()
  }

}
