import { Pipe, PipeTransform } from "@angular/core";
import * as moment from 'moment';

@Pipe({
    name: 'onlyDay'
})
export class onlyDay implements PipeTransform {
    transform(fullDate: string): string {
        let result = moment(fullDate).format('D')
        return result
    }
}



@Pipe({
    name: 'MonthDate'
})
export class MonthDate implements PipeTransform {
    transform(fullDate: string): string {
        let result = moment(fullDate).format('MM.DD')
        return result
    }
}

@Pipe({
    name: 'PhoneHyphen'
})
export class PhoneHyphen implements PipeTransform {
    transform(PhoneNum: string): string {
        return (PhoneNum.trim()).replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, "$1-$2-$3")
    }
}

@Pipe({name: 'fileSize'})
export class FileSizePipe implements PipeTransform {

  private units = [
    'bytes',
    'KB',
    'MB',
    'GB',
    'TB',
    'PB'
  ];

  transform(bytes: number = 0, precision: number = 2 ) : string {
    if ( isNaN( parseFloat( String(bytes) )) || ! isFinite( bytes ) ) return '?';

    let unit = 0;

    while ( bytes >= 1024 ) {
      bytes /= 1024;
      unit ++;
    }

    return bytes.toFixed( + precision ) + ' ' + this.units[ unit ];
  }
}
