import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-search-check',
  templateUrl: './search-check.component.html',
  styleUrls: ['./search-check.component.scss']
})
export class SearchCheckComponent implements OnInit {
  @Output() searchEvent = new EventEmitter<any>();
  @Input() checkList:Array<any>
  @Input() title
  constructor() { }

  ngOnInit() {
  }
  changeSelected(){
    this.searchEvent.emit(this.checkList)
  }
}

