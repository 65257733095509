import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { Globals } from 'app/services/globals';
import { SelectionModel } from '@angular/cdk/collections';
import * as _ from 'underscore';
import { APIService } from 'app/services/api.service';

@Component({
  selector: 'app-select-grade-dialog',
  templateUrl: './select-grade-dialog.component.html',
  styleUrls: ['./select-grade-dialog.component.scss']
})
export class SelectGradeDialogComponent implements OnInit {
  selection = new SelectionModel<any>(true, []);
  displayedColumns: string[] = ['1', '2', '3', '4']
  dataSource: any
  totalCnt
  size: number = 10
  currentPage: number = 1

  @Input() data:{
    grades: Array<any>
  }
  
  constructor(public api: APIService,
    private formBuiler: FormBuilder,
    private dlg: MatDialog,
    private snackBar: MatSnackBar,
    public global: Globals,
    private dialog:MatDialogRef<SelectGradeDialogComponent>) {
  }

  ngOnInit() {
    this.getList()
  }

  getList(){
    this.api.getGrades().subscribe(response => {
      this.dataSource = response
      this.totalCnt = this.dataSource.length
    })
  }

  onPaginateChange(event) {
    this.currentPage = event.pageIndex + 1
    this.getList()
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.forEach(row => this.selection.select(row));
  }
  
  reg(){
    if(this.selection.selected && this.selection.selected.length > 0){
      if(this.data.grades.length > 0){
        let isExist = false
        this.data.grades.forEach(element => {
          if(_.find(this.selection.selected, function(data){ return data.name == element.name })){
            isExist = true
          }
        })
        if(isExist){
          this.snackBar.open("이미 등록된 등급입니다.", "확인", {
            duration: 2000,
          })
        }else{
          this.dialog.close(this.selection.selected)
        }
      }else{
        this.dialog.close(this.selection.selected)
      }
    }else{
      this.snackBar.open("등급을 선택해주세요", "확인", {
        duration: 2000,
      })
    }
  }
}
