import {Environment} from '../../app/Environment'

export class FroalaOptions{
    constructor(token, url, global,height:number=400){
        return {
            attribution: false,
            language: 'ko',
            height: height,
            charCounterCount : false,
            imageUploadURL: Environment.BACKEND_HOST + url,
            //imageResizeWithPercent: true,
            videoResponsive: true,
            requestHeaders: {
              "x-auth-token": token
            },
            // toolbarButtons: {
            //   'moreText': {
            //     'buttons': ['bold', 'italic', 'underline','fontFamily', 'fontSize', 'strikeThrough', 'subscript', 'superscript', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting'],
            //     'buttonsVisible': 4
            //   },
            //   'moreParagraph': {
            //     'buttons': ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote'],
            //     'buttonsVisible': 0
            //   },
            //   'moreRich': {
            //     'buttons': ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR'],
            //     'buttonsVisible': 4
            //   },
            //   'moreMisc': {
            //     'buttons': ['undo', 'redo', 'fullscreen', 'print', 'getPDF', 'spellChecker', 'selectAll', 'html', 'help'],
            //     'buttonsVisible': 4
            //   }
            // },
            imageMaxSize: 5 * 1024 * 1024,//5MB
            imageAllowedTypes: ['jpeg', 'jpg', 'png'],
            placeholderText: "내용을 입력해주세요",
            key: global.froalaKey,
            events : {
              'froalaEditor.image.beforeUpload': function (e, editor, images){
                 editor.opts.imageUploadParams = {name: images[0].name}
              },
              'froalaEditor.image.uploaded': function (e, editor, response) {
                
              }
            }
          }
    }
}