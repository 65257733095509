import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, ValidatorFn, ValidationErrors } from '@angular/forms';
import { APIService } from 'app/services/api.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Globals } from 'app/services/globals';
import { ActivatedRoute, Router } from '@angular/router';
import { ModifyPasswordDialogComponent } from 'app/dialogs/modify-password-dialog/modify-password-dialog.component';

@Component({
  selector: 'app-slide-banner-detail',
  templateUrl: './slide-banner-detail.component.html',
  styleUrls: ['./slide-banner-detail.component.scss']
})
export class SlideBannerDetailComponent implements OnInit {
  idx: number
  selectedPCImage
  selectedImagePCFile
  selectedImagePCFileKey
  selectedMobileImage
  selectedImageMobileFile
  selectedImageMobileFileKey
  form: FormGroup = this.formBuilder.group({
    title: '',
    content: '',
    image_pc: '',
    pc_bg_color: '',
    image_mobile: '',
    mobile_bg_color: '',
    target_url: '',
    target_window: '',
    display_idx: '1'
  })
  mode: string
  constructor(public api: APIService,
    public dialog: MatDialog,
    private global: Globals,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private router: Router) {
      this.mode = this.route.snapshot.queryParams['mode'] || 'info';
      this.idx = this.route.snapshot.queryParams['idx']|| undefined;

      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      };
    }

  ngOnInit() {

    if(this.mode == "info" || this.mode == "modify"){
      this.getInfo()
    }
  }

  setInfo(data){
    if(data){
      this.form = this.formBuilder.group({
        title: data.title,
        content: data.content,
        image_pc: data.image_file_key.file_name,
        pc_bg_color: data.pc_bg_color,
        image_mobile: data.mobile_image_file_key.file_name,
        mobile_bg_color: data.mobile_bg_color,
        target_url: data.target_url,
        target_window: data.target_window,
        display_idx: data.display_idx
      })

      if(data.image_file_key){
        this.selectedImagePCFileKey = data.image_file_key.key
      }

      if(data.mobile_image_file_key){
        this.selectedImageMobileFileKey = data.mobile_image_file_key.key
      }

    }
  }

  getInfo(){
    // this.setInfo({title: "슬라이드의 제목입니다.", link: "https://www.bullbyun.com", new_window: "use", order: "2"})
    this.api.getBannerDetail(this.idx).subscribe(response => {
      this.setInfo(response)
    })
  }

  save(){
    let vo = {
      title: this.form.value.title,
      content: this.form.value.content,
      image_file_key: (this.selectedImagePCFileKey? this.selectedImagePCFileKey:''),
      pc_bg_color: this.form.value.pc_bg_color,
      mobile_image_file_key: (this.selectedImageMobileFileKey? this.selectedImageMobileFileKey:''),
      mobile_bg_color: this.form.value.mobile_bg_color,
      target_url: this.form.value.target_url,
      target_window:this.form.value.target_window,
      display_idx:parseInt(this.form.value.display_idx)
    }
    if(this.mode == 'modify'){
      this.api.modifyBanner(this.idx,vo).subscribe(response=>{
        if(response){
          this.router.navigate(['/banner', this.form.value.title], { queryParams: {idx:this.idx, mode: 'info' } })
        }
      })
    }else{
      this.api.createBanner(vo).subscribe(response=>{
        if(response){
          this.router.navigate(['/banner', this.form.value.title], { queryParams: {idx: response.idx, mode: 'info' } })
        }
      })
    }

  }

  mod(){
    //수정
    this.router.navigate(['/banner', this.form.value.title], { queryParams: {idx:this.idx, mode: 'modify' } })
  }

  modifyPassword(){
    let dialogRef = this.dialog.open(ModifyPasswordDialogComponent)
    dialogRef.componentInstance.data = { id: this.form.value.id }
  }

  goList(){
    this.router.navigate(["/banner"])
  }

  async onFilesAdded(file, type) {
    if (file && file[0]) {
      file = file[0]

      let response = await this.api.uploadFile(file, "image")
      if(response){
        if(type == "pc"){
          this.selectedImagePCFile = file
          this.selectedImagePCFileKey = response.key
          this.form.controls.image_pc.setValue(file.name)
        }else{
          this.selectedImageMobileFile = file
          this.selectedImageMobileFileKey = response.key
          this.form.controls.image_mobile.setValue(file.name)
        } 
      }
    }
  }
  
  deleteImage(type) {
    if(type == 'pc'){
      this.selectedImagePCFile = undefined
      this.selectedImagePCFileKey = undefined
      this.form.controls.image_pc.setValue("")
    }else{
      this.selectedImageMobileFile = undefined
      this.selectedImageMobileFileKey = undefined
      this.form.controls.image_mobile.setValue("")
    }
  }
}