import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { APIService } from 'app/services/api.service';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { DefaultDialogComponent } from 'app/dialogs/default-dialog/default-dialog.component';
import * as _ from 'underscore';

@Component({
  selector: 'app-slide-banner-management',
  templateUrl: './slide-banner-management.component.html',
  styleUrls: ['./slide-banner-management.component.scss']
})
export class SlideBannerManagementComponent implements OnInit {
  displayedColumns: string[] = ['1', '2', '3', '4']
  dataSource: any
  totalCnt
  size: number = 10
  currentPage: number = 1
  form: FormGroup = this.formBuilder.group({
    title: '',
    type: '',
    start: '',
    end: '',
    left: 0,
    top: 0,
    width: 0,
    height: 0,
    created_at: '',
  })
  
  constructor(
    public api: APIService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private router: Router,
    private changeDetectorRefs: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.getList()
  }

  reg(){
    this.router.navigate(['/banner', 'reg-slide-banner'], { queryParams: { mode: 'reg' } })
  }

  showInfo(data){
    this.router.navigate(['/banner', data.title], { queryParams: {idx:data.idx, mode: 'info' } })
  }
  
  del(data) {
    const dialogRef = this.dialog.open(DefaultDialogComponent)
    dialogRef.componentInstance.data = { content: '삭제 하시겠습니까?', activeText: '삭제', closeText: '취소' }
    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        this.api.deleteBanner(data.idx).subscribe(response => {
          if (response) {
            this.refresh()
          }
        })
      }
    })
  }

  getList() {
    this.api.getBannerList().subscribe(response => {
      this.dataSource = new MatTableDataSource(response.data)
    })
  }

  moveItem(dir, element) {
    let idx = _.indexOf(this.dataSource.data, element)
    let targetElement
    switch (dir) {
      case "down":
        // this.dataSource = this.swap(this.dataSource, idx, idx+1)
        targetElement= this.dataSource.data[idx+1]
        break;
      case "up":
        // this.dataSource = this.swap(this.dataSource, idx, idx-1)
        targetElement= this.dataSource.data[idx-1]
        break;
    }
    this.api.modifyBanner(element.idx, { 'display_idx': targetElement.display_idx }).subscribe(response => {
      if (response) {
        this.refresh()
      }
    })
  }

  swap(sourceArray, idx1, idx2): []{//위치를 바꾼 후, 새로운 배열 리턴
    var items = Object.assign([], sourceArray);

    let item = items[idx1]
    items[idx1] = items[idx2]
    items[idx2] = item

    return items
  }


  changeVisible(idx, visible) {
    this.api.modifyBanner(idx, { 'visible': visible }).subscribe(response => {
      this.refresh()
    })
  }
  
  refresh(){
    this.currentPage = 1
    this.dataSource = []
    this.totalCnt = 0

    this.getList()
  }
}


