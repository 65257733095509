import { Routes, RouterModule } from '@angular/router';

import { NgModule } from '@angular/core';
import { MemberManagementComponent } from './pages/member/member-management/member-management.component';
import { RegMemberComponent } from './dialogs/reg-member/reg-member.component';
import { RegBoardComponent } from './dialogs/reg-board/reg-board.component';
import { LoginComponent } from './pages/authentication/login/login.component';
import { LoginGuard } from './services/login.guard';
import { DefaultDialogComponent } from './dialogs/default-dialog/default-dialog.component';
import { MemberDetailComponent } from './pages/member/member-detail/member-detail.component';
import { PopupLayerManagementComponent } from './pages/popup/popup-layer-management/popup-layer-management.component';
import { PopupLayerDetailComponent } from './pages/popup/popup-layer-detail/popup-layer-detail.component';
import { SlideBannerManagementComponent } from './pages/banner/slide-banner-management/slide-banner-management.component';
import { SlideBannerDetailComponent } from './pages/banner/slide-banner-detail/slide-banner-detail.component';
import { BoardContentManagementComponent } from './pages/board/board-content-management/board-content-management.component';
import { BoardManagementComponent } from './pages/board/board-management/board-management.component';
import { BoardDetailComponent } from './pages/board/board-detail/board-detail.component';
import { BoardContentDetailComponent } from './pages/board/board-content-detail/board-content-detail.component';
import { TermsComponent } from './pages/setting/terms/terms.component';
import { SeoManagementComponent } from './pages/setting/seo-management/seo-management.component';
import { SignupManagementComponent } from './pages/setting/signup-management/signup-management.component';
import { EmailManagementComponent } from './pages/setting/email-management/email-management.component';
import { EmailTemplateManagementComponent } from './pages/setting/email-template/email-template-management/email-template-management.component';
import { EmailTemplateDetailComponent } from './pages/setting/email-template/email-template-detail/email-template-detail.component';
import { NormalBannerDetailComponent } from './pages/banner/normal-banner-detail/normal-banner-detail.component';
import { NormalBannerManagementComponent } from './pages/banner/normal-banner-management/normal-banner-management.component';
import { SideBannerManagementComponent } from './pages/banner/side-banner-management/side-banner-management.component';
import { SideBannerDetailComponent } from './pages/banner/side-banner-detail/side-banner-detail.component';
const routes: Routes = [
  { path: '', redirectTo: 'member/member-management', pathMatch: 'full' },
  {
    path: 'member', children: [
      { path: '', redirectTo: 'member-management', pathMatch: 'full' },
      { path: 'member-management', component: MemberManagementComponent, canActivate: [LoginGuard] },
      { path: 'reg-member', component: MemberDetailComponent },
      { path: ':id', component: MemberDetailComponent }
    ], canActivate: [LoginGuard]
  },
  {
    path: 'popup', children: [
      { path: '', redirectTo: 'popup-layer-management', pathMatch: 'full' },
      { path: 'popup-layer-management', component: PopupLayerManagementComponent },
      { path: 'reg-popup-layer', component: PopupLayerDetailComponent },
      { path: ':id', component: PopupLayerDetailComponent }
    ], canActivate: [LoginGuard]
  },
  {
    path: 'banner', children: [
      { path: '', redirectTo: 'slide-banner-management', pathMatch: 'full' },
      { path: 'slide-banner-management', component: SlideBannerManagementComponent },
      { path: 'reg-slide-banner', component: SlideBannerDetailComponent },
      { path: ':id', component: SlideBannerDetailComponent },
    ], canActivate: [LoginGuard]
  },
  {
    path: 'normal-banner', children: [
      { path: '', redirectTo: 'normal-banner-management', pathMatch: 'full' },
      { path: 'normal-banner-management', component: NormalBannerManagementComponent },
      { path: 'reg-normal-banner', component: NormalBannerDetailComponent },
      { path: ':id', component: NormalBannerDetailComponent },
    ], canActivate: [LoginGuard]
  },
  {
    path: 'side-banner', children: [
      { path: '', redirectTo: 'side-banner-management', pathMatch: 'full' },
      { path: 'side-banner-management', component: SideBannerManagementComponent },
      { path: 'reg-side-banner', component: SideBannerDetailComponent },
      { path: ':id', component: SideBannerDetailComponent },
    ], canActivate: [LoginGuard]
  },
  {
    path: 'board', children: [
      { path: '', redirectTo: 'board-management', pathMatch: 'full' },
      { path: 'board-management', component: BoardManagementComponent },
      { path: 'reg-board', component: BoardDetailComponent },
      { path: ':id', component: BoardDetailComponent }
    ], canActivate: [LoginGuard]
  },
  {
    path: 'board-content', children: [
      { path: '', redirectTo: 'board-content-management', pathMatch: 'full' },
      { path: 'board-content-management', component: BoardContentManagementComponent },
      { path: 'reg-board-content', component: BoardContentDetailComponent },
      { path: ':id', component: BoardContentDetailComponent }
    ], canActivate: [LoginGuard]
  },
  {
    path: 'setting', children: [
      { path: 'signup-management', component: SignupManagementComponent },
      { path: 'seo-management', component: SeoManagementComponent },
      { path: 'terms', component: TermsComponent },
      { path: 'email-management', component: EmailManagementComponent },
      { path: 'email-template-management', component: EmailTemplateManagementComponent },
      { path: 'email-template-detail', component: EmailTemplateDetailComponent }
    ], canActivate: [LoginGuard]
  },

  // { path: 'reg-member', component: RegMemberComponent, canActivate: [LoginGuard] },
  // { path: 'reg-board', component: RegBoardComponent, canActivate: [LoginGuard] },
  { path: 'login', component: LoginComponent }
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes,
      {
        useHash: false,
        onSameUrlNavigation: "reload",
        scrollPositionRestoration: "enabled",
        anchorScrolling: 'enabled'
      })
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})
export class AppRoutingModule {
}
