export class Board {
    idx: number
    board_category_name: string
    display_idx: number
    image_key: string
    title: string
    content: string
    visible: boolean
    creator: string
    created_at: string
    updated_at: string
    sub_cnt:number
    image_url:string
    file_name:string
}