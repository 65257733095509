import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, RequiredValidator } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { DefaultDialogComponent } from 'app/dialogs/default-dialog/default-dialog.component';
import { PreviewDialogComponent } from 'app/dialogs/preview-dialog/preview-dialog.component';
import { APIService } from 'app/services/api.service';
import { Globals } from 'app/services/globals';
import * as _ from 'underscore';
@Component({
  selector: 'app-email-template-detail',
  templateUrl: './email-template-detail.component.html',
  styleUrls: ['./email-template-detail.component.scss']
})
export class EmailTemplateDetailComponent implements OnInit {
  categories = []
  selectedMembers = []
  mode: string
  idx: number
  board_list = []
  key_list = []
  nameChecked: boolean = false
  form: FormGroup = this.formBuilder.group({
    name: "",
    title: "",
    type: "",
    target: "",
    key_list: [],
    content: ""
  })
  tokenTarget = ''
  constructor(public api: APIService,
    public dialog: MatDialog,
    private global: Globals,
    // private location: Location,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private router: Router,
  ) {
    this.mode = this.route.snapshot.queryParams['mode'] || 'info';
    this.idx = this.route.snapshot.queryParams['id']
    this.form.get("type").valueChanges.subscribe(type => {
      this.form.controls['key_list'].reset()
      this.form.controls['target'].reset()
      if (type == 'board') {
        this.api.getBoards(1, 9999).subscribe(response => {
          this.board_list = response.data
        })

      }
    })
    this.form.get("target").valueChanges.subscribe(target => {
      if (target) {
        if (this.form.value.type == 'board' && target.length > 0) {
          this.api.getBoardDetail(target).subscribe(response => {
            let board_key_list = [
              { 'name': '제목', 'key': 'title' },
              { 'name': '내용', 'key': 'content' },
              { 'name': '이름', 'key': 'name' },
              { 'name': '휴대폰번호', 'key': 'phone' },
              { 'name': '아이디', 'key': 'creator_id' },
              { 'name': '작성날짜', 'key': 'created_at' },
              { 'name': '사이트 이름', 'key': 'site_name' },
              { 'name': '게시판 이름', 'key': 'board_name' },
              { 'name': '카테고리 이름', 'key': 'category_name' },
              { 'name': '바로가기 링크', 'key': 'target_url' }
            ]
            let list = []
            for (let info of board_key_list) {
              info.key = `#{${info.key}}`
              list.push(info)
            }
            let additional_field_settings = response.settings.additional_field_settings
            if (additional_field_settings) {
              for (let i = 0; i < additional_field_settings.length; i++) {
                if (additional_field_settings[i].use == true) {
                  list.push({ 'name': additional_field_settings[i].title, 'key': '#{etc_field' + (i + 1) + '}' })
                }
              }
            }

            this.form.controls['key_list'].setValue(list)
          })
        }
      }

    })
  }

  ngOnInit() {
    if (this.mode == "info" || this.mode == "modify") {
      this.getInfo()
    }

  }

  nameCheck() {
    this.api.getTemplateList(1, 9999).subscribe(response => {
      let exist = _.find(response.data, (item) => {
        return item.name == this.form.value.name
      })
      if (exist && this.idx != exist.idx) {
        const dialogRef = this.dialog.open(DefaultDialogComponent)
        dialogRef.componentInstance.data = { content: '이미 존재하는 템플릿 이름입니다.', closeText: '확인' }
        this.nameChecked = false
      } else {
        const dialogRef = this.dialog.open(DefaultDialogComponent)
        dialogRef.componentInstance.data = { content: '사용 가능합니다.', closeText: '확인' }
        this.nameChecked = true
      }
    })
  }

  getInfo() {
    this.api.getTemplateDetail(this.idx).subscribe(response => {
      this.form.controls['name'].setValue(response.name)
      this.form.controls['title'].setValue(response.title)
      this.form.controls['type'].setValue(response.type)
      this.form.controls['target'].setValue(response.target)
      this.form.controls['content'].setValue(response.content)
      this.nameChecked = true
    })
  }

  showPreview() {
    const dialogRef = this.dialog.open(PreviewDialogComponent)
    dialogRef.componentInstance.data = { content: this.form.value.content }
  }

  save() {
    if (!this.nameChecked) {
      this.snackBar.open("템플릿 이름 중복체크를 해주세요.", "확인", {
        duration: 2000,
      })
      return
    }
    let vo = this.form.value
    if (this.mode == "reg") {
      this.api.createTemplate(vo).subscribe(response => {
        const dialogRef = this.dialog.open(DefaultDialogComponent)
        dialogRef.componentInstance.data = { content: '등록되었습니다.', closeText: '확인' }
        dialogRef.afterClosed().subscribe(() => {
          this.router.navigate(['/setting/email-template-management'], { queryParams: { mode: 'info', id: response.idx } })
        })
      })
    } else {
      this.api.modifyTemplate(this.idx, vo).subscribe(response => {
        const dialogRef = this.dialog.open(DefaultDialogComponent)
        dialogRef.componentInstance.data = { content: '저장되었습니다.', closeText: '확인' }
        dialogRef.afterClosed().subscribe(response => {
          this.router.navigate(['/setting/email-template-management'], { queryParams: { mode: 'info', id: this.idx } })
        })
      })
    }
  }

  goList() {
    this.router.navigate(['/setting/email-template-management'])
  }
  del() {
    const dialogRef = this.dialog.open(DefaultDialogComponent)
    dialogRef.componentInstance.data = { content: '삭제 하시겠습니까?', activeText: '삭제', closeText: '취소' }
    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        this.api.delTemplate(this.idx).subscribe(response => {
          this.router.navigate(['/setting/email-template-management'])
        })
      }
    })
  }
  addToken(target, info) {
    if (target != '') {
      const start = target.selectionStart
      const end = target.selectionEnd
      if (start || end == '0') {
        let formName = this.tokenTarget
        this.form.controls[formName].setValue(this.form.value[formName].slice(0, start) + info.key + this.form.value[formName].slice(end, this.form.value[formName].length))
        target.selectionStart = start
        target.selectionEnd = end
      }
    }


  }
}
