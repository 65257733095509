import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';
import { AppRoutingModule } from './app-routing.module';
import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { SampleModule } from 'app/main/sample/sample.module';
import { MemberManagementComponent } from './pages/member/member-management/member-management.component';
import { MatDialogModule, MatFormFieldModule, MatInputModule, MatCheckboxModule, MAT_DATE_LOCALE, MatTableModule, MatPaginatorModule, MatSelectModule, MatChipsModule, MatAutocompleteModule, MatSlideToggleModule, MatDatepickerModule, MatRadioModule, MatToolbarModule, MatTabsModule, MatProgressSpinnerModule, MatCardModule, MatSnackBarModule } from '@angular/material';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SearchDateComponent } from './component/search-options/search-date/search-date.component';
import { SearchKeywordComponent } from './component/search-options/search-keyword/search-keyword.component';
import { SearchCheckComponent } from './component/search-options/search-check/search-check.component';
import { RegMemberComponent } from './dialogs/reg-member/reg-member.component';
import { RegBoardComponent } from './dialogs/reg-board/reg-board.component';
import { LoginComponent } from './pages/authentication/login/login.component';
import { DefaultDialogComponent } from './dialogs/default-dialog/default-dialog.component';
import { TokenInterceptorService } from './services/token-interceptor.service';
import { LoginGuard } from './services/login.guard';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { APP_BASE_HREF } from '@angular/common';
import { onlyDay, MonthDate, PhoneHyphen, FileSizePipe } from './common/pipe';
import { NgxSpinnerModule } from "ngx-spinner";
import { PopupLayerManagementComponent } from './pages/popup/popup-layer-management/popup-layer-management.component';
import { SlideBannerManagementComponent } from './pages/banner/slide-banner-management/slide-banner-management.component';
import { BoardContentManagementComponent } from './pages/board/board-content-management/board-content-management.component';
import { SignupManagementComponent } from './pages/setting/signup-management/signup-management.component';
import { SeoManagementComponent } from './pages/setting/seo-management/seo-management.component';
import { TermsComponent } from './pages/setting/terms/terms.component';
import { MemberDetailComponent } from './pages/member/member-detail/member-detail.component';
import { ModifyPasswordDialogComponent } from './dialogs/modify-password-dialog/modify-password-dialog.component';
import { PopupLayerDetailComponent } from './pages/popup/popup-layer-detail/popup-layer-detail.component';
import { NgbTimepickerModule, NgbTooltipModule, NgbRatingModule, NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import { SlideBannerDetailComponent } from './pages/banner/slide-banner-detail/slide-banner-detail.component';
import { BoardManagementComponent } from './pages/board/board-management/board-management.component';
import { BoardDetailComponent } from './pages/board/board-detail/board-detail.component';
import { BoardContentDetailComponent } from './pages/board/board-content-detail/board-content-detail.component';
import { SelectGradeDialogComponent } from './dialogs/select-grade-dialog/select-grade-dialog.component';
import { SelectMemberDialogComponent } from './dialogs/select-member-dialog/select-member-dialog.component';
import { PasswordAuthenticationDialogComponent } from './dialogs/password-authentication-dialog/password-authentication-dialog.component';
import { EmailManagementComponent } from './pages/setting/email-management/email-management.component';
import { EmailTemplateManagementComponent } from './pages/setting/email-template/email-template-management/email-template-management.component';
import { EmailTemplateDetailComponent } from './pages/setting/email-template/email-template-detail/email-template-detail.component';
import { PreviewDialogComponent } from './dialogs/preview-dialog/preview-dialog.component';
import { NormalBannerManagementComponent } from './pages/banner/normal-banner-management/normal-banner-management.component';
import { NormalBannerDetailComponent } from './pages/banner/normal-banner-detail/normal-banner-detail.component';
import { SideBannerManagementComponent } from './pages/banner/side-banner-management/side-banner-management.component';
import { SideBannerDetailComponent } from './pages/banner/side-banner-detail/side-banner-detail.component';
import { ChipComponent } from './component/chip/chip.component';
// import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
// const appRoutes: Routes = [
//     {
//         path      : '**',
//         redirectTo: 'sample'
//     }
// ];

@NgModule({
    declarations: [
        AppComponent,
        MemberManagementComponent,
        MemberDetailComponent,
        DefaultDialogComponent,
        SelectGradeDialogComponent,
        SelectMemberDialogComponent,
        ModifyPasswordDialogComponent,
        RegBoardComponent,
        SearchDateComponent,
        SearchKeywordComponent,
        SearchCheckComponent,
        RegMemberComponent,
        LoginComponent,
        PopupLayerManagementComponent,
        SlideBannerManagementComponent,
        BoardContentManagementComponent,
        SignupManagementComponent,
        SeoManagementComponent,
        TermsComponent,


        //pipes
        onlyDay,
        MonthDate,
        PhoneHyphen,
        FileSizePipe,
        PopupLayerDetailComponent,
        SlideBannerDetailComponent,
        BoardManagementComponent,
        BoardDetailComponent,
        BoardContentDetailComponent,
        SelectGradeDialogComponent,
        SelectMemberDialogComponent,
        PasswordAuthenticationDialogComponent,
        EmailManagementComponent,
        EmailTemplateManagementComponent,
        EmailTemplateDetailComponent,
        PreviewDialogComponent,
        NormalBannerManagementComponent,
        NormalBannerDetailComponent,
        SideBannerManagementComponent,
        SideBannerDetailComponent,
        ChipComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        DragDropModule,
        FroalaEditorModule.forRoot(),
        FroalaViewModule.forRoot(),
        TranslateModule.forRoot(),
        NgxSpinnerModule,
        MatMomentDateModule,
        // Material moment date module

        // ng-bootstrap
        NgbTimepickerModule,
        NgbRatingModule,
        NgbTooltipModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatTableModule,
        MatPaginatorModule,
        MatSelectModule,
        MatSnackBarModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatSlideToggleModule,
        MatDatepickerModule,
        MatRadioModule,
        MatToolbarModule,
        MatTabsModule,
        MatCardModule,
        MatProgressSpinnerModule,


        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        SampleModule,
    ],
    bootstrap: [
        AppComponent
    ],
    providers: [
        { 
            provide: APP_BASE_HREF, 
            useValue: '/' 
          },
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true },
        LoginGuard,
        NgbRatingConfig,
    ],
    entryComponents: [DefaultDialogComponent,
        SelectGradeDialogComponent,
        SelectMemberDialogComponent,
        ModifyPasswordDialogComponent,
        PasswordAuthenticationDialogComponent,
        PreviewDialogComponent],
})
export class AppModule {
}
