import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
// import { APIService } from 'app/services/api.service';
// import { Globals } from 'app/services/globals';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { MatDialog } from '@angular/material';
import { Globals } from 'app/services/globals';
import { APIService } from 'app/services/api.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FormBuilder} _formBuilder
   */
  constructor(
    public api: APIService,
    private global: Globals,
    private router: Router,
    public dialog: MatDialog,
    private _fuseConfigService: FuseConfigService,
    private _formBuilder: FormBuilder
  ) {
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        }
      }
    };
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.loginForm = this._formBuilder.group({
      id: ['', [Validators.required]],
      password: ['', Validators.required]
    });

  }

  ngOnDestroy() {
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: false
        }
      }
    };
  }

  login() {
    this.api.login(this.loginForm.value.id, this.loginForm.value.password).subscribe(response => {
      this.global.logIn(response.token)
      this.global.setUser(response.user)
      this.router.navigate(["/"]);
    })
  }
}
