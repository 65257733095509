import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { APIService } from 'app/services/api.service';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Globals } from 'app/services/globals';

@Component({
  selector: 'app-password-authentication-dialog',
  templateUrl: './password-authentication-dialog.component.html',
  styleUrls: ['./password-authentication-dialog.component.scss']
})
export class PasswordAuthenticationDialogComponent implements OnInit {
  form: FormGroup = this.formBuiler.group({
    pw: ['', Validators.required]
  })
  constructor(
    private api: APIService,
    private formBuiler: FormBuilder,
    private dlg: MatDialog,
    public global: Globals,
    private dialog:MatDialogRef<PasswordAuthenticationDialogComponent>
  ) { }

  ngOnInit() {
  }

  confirm(){
    if(this.form.valid){
      this.dialog.close()
    }
  }

}
