import { Injectable } from '@angular/core';
import * as jwtDecode from 'jwt-decode';

export interface JwtPayload {
  dong: string;
  exp: number;
  iat: number;
  idx: number;
  id: string;
  scopes: Array<string>;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() { }

  public getToken(): string {
    return localStorage.getItem('id_token');
  }

  public getPayload(): JwtPayload {
    return jwtDecode(this.getToken());
  }

  // public isAuthenticated(): boolean {
  //   const token = this.getToken();
  //   return tokenNotExpired(null, token);
  // }
}
