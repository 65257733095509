import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { DefaultDialogComponent } from 'app/dialogs/default-dialog/default-dialog.component';
import { APIService } from 'app/services/api.service';
import { Globals } from 'app/services/globals';

@Component({
  selector: 'app-email-template-management',
  templateUrl: './email-template-management.component.html',
  styleUrls: ['./email-template-management.component.scss']
})
export class EmailTemplateManagementComponent implements OnInit {
  displayedColumns: string[] = ['1', '2', '3', '4', '5']
  dataSource: any
  totalCnt
  size: number = 10
  currentPage: number = 1
  form: FormGroup = this.formBuilder.group({
    searchType: '',
    searchKeyword: ''
  })
  constructor(
    public api: APIService,
    public dialog: MatDialog,
    public global: Globals,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router
  ) { }

  ngOnInit() {
    this.getList()
  }

  getList(){
    this.api.getTemplateList(this.currentPage, this.size,undefined, undefined, this.form.value.searchType, this.form.value.searchKeyword).subscribe(response=>{
      if(response){
        this.dataSource = response.data
        this.totalCnt = response.pagination.total_cnt
      }
    })
  }

  write() {
    this.router.navigate(['/setting/email-template-detail'], { queryParams: { mode: 'reg' } })
  }

  showInfo(data) {
    this.router.navigate(['/setting/email-template-detail'], { queryParams: { mode: 'info', id: data.idx } })
  }

  del(data) {
    const dialogRef = this.dialog.open(DefaultDialogComponent)
    dialogRef.componentInstance.data = { content: '삭제 하시겠습니까?', activeText: '삭제', closeText: '취소' }
    dialogRef.afterClosed().subscribe(response => {
      if (response) {
                this.api.delTemplate(data.idx).subscribe(response => {
          if (response) {
            this.refreshList()
          }
        })
      }
    })
  }


  refreshList(){
    this.currentPage = 1
    this.dataSource = []
    this.totalCnt = 0

    this.getList()
  }

  changeVisible(idx, use) {
    this.api.modifyTemplate(idx, { 'use': use }).subscribe(response => {
    })
  }

  search() {
    if(this.form.valid){
      this.getList()
    }
  }

  onPaginateChange(event) {
    this.currentPage = event.pageIndex + 1
    this.getList()
  }
}
