import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { APIService } from 'app/services/api.service';
import { Globals } from 'app/services/globals';
import { Board } from 'app/models/board';
// import * as ClassicEditor from '../../../assets/js/ckeditor/ckeditor';
import { UploadAdapter } from 'app/common/upload-adapter';
import { FroalaOptions } from '../../models/froala-options';
import { AuthService } from 'app/services/auth.service';
@Component({
  selector: 'app-reg-board',
  templateUrl: './reg-board.component.html',
  styleUrls: ['./reg-board.component.scss']
})
export class RegBoardComponent implements OnInit {
  @Input() element: Board
  form: FormGroup
  file
  imgURL
  categories: Array<{ value: string, display: string }> = []
  exposure_date
  //ckeditor setting
  // config = {
  //   removePlugins: ['ImageCaption'],
  //   language: 'ko',
  //   image: {
  //     toolbar: ['imageStyle:alignLeft', 'imageStyle:full', 'imageStyle:alignRight'],
  //     styles: [
  //       'full',
  //       'alignLeft',
  //       'alignRight'
  //     ]
  //   }
  // }
  // public Editor = ClassicEditor
  editorOptions: Object
  public model = {
    editorData: ''
  };

  constructor(
    public global: Globals,
    public api: APIService,
    private _formBuiler: FormBuilder,
    private dialog: MatDialogRef<RegBoardComponent>,
    private auth: AuthService,
  ) {
    if (this.auth.getToken() != null) {
      var token: string = this.auth.getToken()

      if (token != null && token != undefined && token.length > 0) {
        this.editorOptions = new FroalaOptions(token, "/api/v1/files/image", this.global)
      }
    }

  }

  ngOnInit() {
    // if (this.global.normalBoardCategory && this.global.normalBoardCategory.length > 0) {
    //   for (let category of this.global.normalBoardCategory) {
    //     this.categories.push({ value: category.category_name, display: category.category_name })
    //   }
    // }
    this.form = this._formBuiler.group({
      board_category_name: ['', [Validators.required]],
      image_key: [''],
      file_name: [''],
      title: ['', [Validators.required]],
      content: ['', [Validators.required]]
    })
    if (this.element) {
      this.form.controls['board_category_name'].setValue(this.element.board_category_name);
      this.form.controls['title'].setValue(this.element.title);
      this.form.controls['content'].setValue(this.element.content);
      this.model.editorData = this.element.content
      this.form.controls['image_key'].setValue(this.element.image_key);
      this.form.controls['file_name'].setValue(this.element.file_name);

      this.imgURL = this.element.image_url
    }

  }

  submit() { 
    // this.form.controls['content'].setValue(this.model.editorData)
    // this.form.markAllAsTouched();
    // if (this.form.valid) {
    //   if (!this.element) {
    //     if (this.file) {
    //       this.api.uploadFile(this.file, "image").subscribe(response => {
    //         if (response) {
    //           this.form.controls['image_key'].setValue(response.temp_file_key)
    //           this.api.createNormalBoard(this.form.value).subscribe(response => {
    //             if (response) {
    //               this.dialog.close()
    //             }
    //           })
    //         }
    //       })
    //     } else {
    //       this.api.createNormalBoard(this.form.value).subscribe(response => {
    //         if (response) {
    //           this.dialog.close()
    //         }
    //       })
    //     }

    //   } else {
    //     if (this.file) {
    //       this.api.uploadFile(this.file, "image").subscribe(response => {
    //         if (response) {
    //           this.form.controls['image_key'].setValue(response.temp_file_key)
    //           this.api.modifyNormalBoard(this.element.idx, this.form.value).subscribe(response => {
    //             if (response) {
    //               this.dialog.close()
    //             }
    //           })
    //         }
    //       })
    //     } else {
    //       this.api.modifyNormalBoard(this.element.idx, this.form.value).subscribe(response => {
    //         if (response) {
    //           this.dialog.close()
    //         }
    //       })
    //     }
    //   }

    // }
  }

  profileFileAdded(file) {
    if (file && file[0]) {
      file = file[0]
      let regex = new RegExp("(.*?)\.(jpg|png|jpeg|pdf)$");
      let regexTest = regex.test(file.type);
      if (!regexTest) {
        alert('JPG, PNG, PDF 파일만 등록 가능합니다.')
      } else {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event) => {
          this.imgURL = reader.result;
        }
        this.file = file
        this.form.controls['file_name'].setValue(file.name)
      }
    }
  }

  deleteImage() {
    this.file = undefined
    this.form.controls['file_name'].setValue('')
    this.imgURL = ''
    this.form.controls['image_key'].setValue('')
  }
  dateChange(event) {
    this.exposure_date = event
  }

  onReady(eventData, thisApi) {
    eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
      return new UploadAdapter(loader, thisApi, "image");
    };
  }

}
