import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { APIService } from 'app/services/api.service';
import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { DefaultDialogComponent } from 'app/dialogs/default-dialog/default-dialog.component';
import { NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import { PasswordAuthenticationDialogComponent } from 'app/dialogs/password-authentication-dialog/password-authentication-dialog.component';
import { Globals } from 'app/services/globals';
@Component({
  selector: 'app-board-content-management',
  templateUrl: './board-content-management.component.html',
  styleUrls: ['./board-content-management.component.scss']
})
export class BoardContentManagementComponent implements OnInit {
  displayedColumns: string[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14']
  dataSource: any
  totalCnt
  size: number
  currentPage: number = 1
  form: FormGroup = this.formBuilder.group({
    type: '',
    searchKeyword: ''
  })
  pointOfStar: number = 1
  id
  selectAll: boolean = false
  constructor(
    public api: APIService,
    public dialog: MatDialog,
    public global: Globals,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private ratingConfig: NgbRatingConfig,
    private router: Router,
  ) {
    ratingConfig.max = 5
    ratingConfig.readonly = true
    this.id = this.route.snapshot.queryParams['id']
  }

  ngOnInit() {
    this.api.boardDetail(this.id).subscribe(response => {
      this.size = response.settings.function_settings.pc_page_size
      this.getList()
    })
  }

  write() {
    this.router.navigate(['/board-content', 'reg-board-content'], { queryParams: { mode: 'reg', boardId: this.id } })
  }

  showInfo(data) {
    this.router.navigate(['/board-content', data.idx], { queryParams: { mode: 'info', boardId: this.id } })
  }

  showBoard(data) {

  }

  manageBoard(data) {
    this.router.navigate(['/board-content'])
  }

  del(data) {
    const dialogRef = this.dialog.open(DefaultDialogComponent)
    dialogRef.componentInstance.data = { content: '삭제 하시겠습니까?', activeText: '삭제', closeText: '취소' }
    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        // this.api.delNormalBoard(data.dx).subscribe(response => {
        //   if (response) {
        //     this.refreshList()
        //   }
        // })
      }
    })
  }

  refreshList() {
    this.currentPage = 1
    this.dataSource = []
    this.totalCnt = 0

    this.getList()
  }

  getList() {
    this.api.getBoardContents(this.id, this.currentPage, this.size, this.form.value.type, this.form.value.searchKeyword, 'desc').subscribe(response => {
      for (let property of response.data) {
        property.no = response.pagination.total_cnt - (response.pagination.size * (this.currentPage - 1)) - response.data.indexOf(property)
        property.check_box = false
      }
      this.dataSource = response.data
      this.totalCnt = response.pagination.total_cnt

      if (response.board_settings.function_settings.star_point.max_star_point == 10) {
        this.pointOfStar = 2
      }
    })
  }

  search() {
    if (this.form.valid) {
      this.currentPage = 1
      this.getList()
    }
  }

  onPaginateChange(event) {
    this.currentPage = event.pageIndex + 1
    this.getList()
  }

  changeVisible(idx, visible) {
    this.api.modifyContentVisible(idx, { 'visible': visible }).subscribe(response => {
    })
  }

  // openPasswordAuth(){
  //   this.dialog.open(PasswordAuthenticationDialogComponent)
  // }

  selectAllList() {
    let boolean = false
    if (this.selectAll == true) {
      boolean = false
    } else {
      boolean = true
    }
    for (let data of this.dataSource) {
      data.check_box = boolean
    }
  }
  removeSelectContent() {
    let _this=this
    const dialogRef = this.dialog.open(DefaultDialogComponent)
    dialogRef.componentInstance.data = { content: '선택한 글들을 삭제 하시겠습니까?', activeText: '삭제', closeText: '취소' }
    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        let list = []
        for (let data of this.dataSource) {
          if (data.check_box == true) {
            list.push(data.idx)
          }
        }
        if (list.length > 0) {
          this.api.delBoardContents(this.id, list).subscribe(response => {
            _this.refreshList()
          })
        }
      }
    })

  }
}

