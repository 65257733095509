import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-default-dialog',
  templateUrl: './default-dialog.component.html',
  styleUrls: ['./default-dialog.component.scss']
})
export class DefaultDialogComponent implements OnInit {
  @Input() data:{
    content:string,
    activeText?:string,
    closeText:string,
    activeText2?
  }
  @Input() innerHTML
  constructor() { }

  ngOnInit() {
  }

}
