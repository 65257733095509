import { Component, OnInit, Output,EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-search-keyword',
  templateUrl: './search-keyword.component.html',
  styleUrls: ['./search-keyword.component.scss']
})
export class SearchKeywordComponent implements OnInit {
  @Output() searchEvent = new EventEmitter<any>();
  @Input() title
  @Input() searchType
  @Input() searchTypeHidden:boolean=false
  form: FormGroup;
  constructor(
    private _formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.form = this._formBuilder.group({
      member_type:'',
      type:'',
      keyword:''
    })
  }
  submit(){
    this.searchEvent.emit(this.form.value)
  }
}
