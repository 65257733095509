export class User {
    id: string
    name: string
    nick: string
    email: string
    auth: number
    auth_code: string
    password:string
    status: boolean
    created_at:string
    last_accessed_at: string
}