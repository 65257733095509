import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { APIService } from 'app/services/api.service';
import { Globals } from 'app/services/globals';
import * as _ from 'underscore';

@Component({
  selector: 'app-side-banner-detail',
  templateUrl: './side-banner-detail.component.html',
  styleUrls: ['./side-banner-detail.component.scss']
})
export class SideBannerDetailComponent implements OnInit {
  idx: number
  form: FormGroup = this.formBuilder.group({
    title: '',
    banner_cnt: 0,
    banner_list: [],
    visible: true
  })
  mode: string
  constructor(public api: APIService,
    public dialog: MatDialog,
    private global: Globals,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private router: Router) {
    this.mode = this.route.snapshot.queryParams['mode'] || 'info';
    this.idx = this.route.snapshot.queryParams['idx'] || undefined;

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  ngOnInit() {
    if (this.mode == "info" || this.mode == "modify") {
      this.getInfo()
    }
    this.onChanges()
  }

  onChanges(): void {
    this.form.get('banner_cnt').valueChanges.subscribe(val => {
      this.changeBannerList(val)
    });
  }


  changeBannerList(val) {
    if (!this.form.value.banner_list) {
      this.form.controls['banner_list'].setValue([])
    }
    let newList = []
    if (this.form.value.banner_list.length > val) {
      newList = this.form.value.banner_list.slice(0, val)
    } else if(this.form.value.banner_list.length < val){
      for (let data of this.form.value.banner_list) {
        newList.push(data)
      }
      for (let i = 0; i < (val - this.form.value.banner_list.length); i++) {
        newList.push({
          title: '',
          content: '',
          image_pc: '',
          image_mobile: '',
          target_url: '',
          target_window: 'equal',
        })
      }
    } else {
      newList = this.form.value.banner_list
    }
    this.form.controls['banner_list'].setValue(newList)
  }

  setInfo(data) {
    if (data) {
      for (let banner of data.banner_list) {
        if (banner.image_file_key) {
          banner.selectedImagePCFileKey = banner.image_file_key.key
          banner.selectedImagePCFile = banner.image_file_key
          banner.image_pc = banner.image_file_key.file_name
        }
        if (banner.mobile_image_file_key) {
          banner.selectedImageMobileFileKey = banner.mobile_image_file_key.key
          banner.selectedImageMobileFile = banner.mobile_image_file_key
          banner.image_mobile = banner.mobile_image_file_key.file_name
        }
      }
      this.form.setValue({
        title: data.title,
        banner_cnt: data.banner_cnt,
        banner_list: data.banner_list,
        visible: data.visible
      })
      this.form.controls['banner_list'].setValue(data.banner_list)
    }
  }

  getInfo() {
    this.api.getSideBannerDetail(this.idx).subscribe(data => {
      this.setInfo({
        title: data.title,
        content: data.content,
        banner_cnt: data.banner_list.length,
        banner_list: data.banner_list,
        visible: data.visible
      })
    })
  }


  save() {
    let bannerList = _.map(this.form.value.banner_list, item => {
      return {
        title: item.title,
        content: item.content,
        image_file_key: (item.selectedImagePCFileKey ? item.selectedImagePCFileKey : ''),
        mobile_image_file_key: (item.selectedImageMobileFileKey ? item.selectedImageMobileFileKey : ''),
        target_url: item.target_url,
        target_window: item.target_window,
      }
    })
    let vo = {
      title: this.form.value.title,
      banner_list: bannerList,
      visible: this.form.value.visible
    }

    if (this.mode == 'modify') {
      this.api.modifySideBanner(this.idx, vo).subscribe(response => {
        if (response) {
          this.router.navigate(['/side-banner', this.form.value.title], { queryParams: { idx: this.idx, mode: 'info' } })
        }
      })
    } else {
      this.api.createSideBanner(vo).subscribe(response => {
        if (response) {
          this.router.navigate(['/side-banner', this.form.value.title], { queryParams: { idx: response.idx, mode: 'info' } })
        }
      })
    }

  }

  mod() {
    //수정
    this.router.navigate(['/side-banner', this.form.value.title], { queryParams: { idx: this.idx, mode: 'modify' } })
  }

  goList() {
    this.router.navigate(["/side-banner"])
  }


  async onFilesAdded(file, type, i) {
    if (file && file[0]) {
      file = file[0]

      let response = await this.api.uploadFile(file, "image")
      if (response) {
        let banner_list = this.form.value.banner_list
        if (type == "pc") {
          banner_list[i].selectedImagePCFile = file
          banner_list[i].selectedImagePCFileKey = response.key
          banner_list[i].image_pc = file.name
        } else {
          banner_list[i].selectedImageMobileFile = file
          banner_list[i].selectedImageMobileFileKey = response.key
          banner_list[i].image_mobile = file.name
        }
        this.form.controls['banner_list'].setValue(banner_list)
      }
    }
  }

  deleteImage(type, i) {
    let banner_list = this.form.value.banner_list
    if (type == 'pc') {
      banner_list[i].selectedImagePCFile = undefined
      banner_list[i].selectedImagePCFileKey = undefined
      banner_list[i].image_pc = undefined
    } else {
      banner_list[i].selectedImageMobileFile = undefined
      banner_list[i].selectedImageMobileFileKey = undefined
      banner_list[i].image_mobile = undefined
    }
    this.form.controls['banner_list'].setValue(banner_list)
  }
}
