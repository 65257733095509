import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { APIService } from 'app/services/api.service';
import { MatDialog, MatSnackBar, MatChipInputEvent, MatIconRegistry } from '@angular/material';
import { Globals } from 'app/services/globals';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { SelectGradeDialogComponent } from 'app/dialogs/select-grade-dialog/select-grade-dialog.component';
import { SelectMemberDialogComponent } from 'app/dialogs/select-member-dialog/select-member-dialog.component';
import { DefaultDialogComponent } from 'app/dialogs/default-dialog/default-dialog.component';
import * as _ from 'underscore';

@Component({
  selector: 'app-board-detail',
  templateUrl: './board-detail.component.html',
  styleUrls: ['./board-detail.component.scss']
})
export class BoardDetailComponent implements OnInit {
  useCategory = true
  useAnswer = true
  useReply = true
  useOneOne = true
  isIDChecked = false
  id: string
  categoryTags = []
  boardCategories = []
  templateList = []
  ban_word_list = []
  form1: FormGroup = this.formBuilder.group({
    id: '',
    board_name: '',
    mobile_board_name: '',
    url_prefix: '/boards',
    category_use: 'use',
    start_number: '',
  })
  form2: FormGroup = this.formBuilder.group({
    list_permission: 'ALL',
    read_permission: 'ALL',
    write_permission: 'ALL',
    use_answer: 'use',
    answer_permission: 'ALL',
    use_reply: 'use',
    reply_permission: 'ALL',
    use_oneone: 'use',
    reply_one_one_permission: 'ALL',
    secret_permission: 'ALL'
  })
  form3: FormGroup = this.formBuilder.group({
    author_show_type: "name",
    hide_author: "none",
    admin_show_type: "name",
    use_do_not_modify: false,
    do_not_modify_cnt: "1",
    use_do_not_del: false,
    do_not_del_cnt: "1",
    board_del_option_reply: "with",
    board_del_option_point: "use",
    board_del_option_point_lack: "minus",
    relation_member_use: "use",
    use_star_point: "use",
    write_board_star: "5",
    write_board_point: "10",
    use_board_recommend: "use",
    use_board_non_recommend: "use",
    use_board_view_count: "use",
    view_count_inc: "1",
    ip_duplicate: false,
    secret_board: "none",
    secret_board_title: "origin",
    secret_board_title_template: "",
    secret_reply: "none",
    secret_reply_content_set: true,
    secret_reply_content: "",
    new_icon_hour: "24",
    hot_icon_view_count: "100",
    notice_count: "3",
    notice_only_first: true,
    notice_all_list: true,
    notice_icon_pc: true,
    notice_icon_mobile: true,
    title_length_pc: "30",
    title_length_mobile: "30",
    board_count_of_page_pc: "15",
    board_count_of_page_mobile: "15",
    title_image_use: "use",
    title_image_set_type: "upload_image",
    image_width: "",
    image_height: "",
    image_resize_crop_option: "resize_after_crop",
    image_crop_horizontal_option: "center",
    image_crop_vertical_option: "top",
    list_content_use: "use",
    title_file_use: "use",
    editor_use: "use",
    upload_file_use: "use",
    max_upload_file_size: "25",
    upload_file_show_image: "use",
    image_resize: "500",
    link: "use",
    link_position: "top_of_content",
    show_on_list: "use",
    show_ip: true,
    encrypt_ip: true,
    board_spam: false,
    reply_spam: false,
    content_alert_email_sending:"notuse",
    email_template:"notuse",
    email_template_target:0,
    ban_word: "notuse"
  })
  form4: FormGroup = this.formBuilder.group({
    use_point: 'use',
    read_point: "0",
    write_point: "0",
    reply_point: "0",
    download_point: "0",
  })
  form5: FormGroup = this.formBuilder.group({
    field1: false,
    field1_title: "",
    field2: false,
    field2_title: "",
    field3: false,
    field3_title: "",
    field4: false,
    field4_title: "",
    field5: false,
    field5_title: "",
    field6: false,
    field6_title: "",
  })
  form6: FormGroup = this.formBuilder.group({
    respectively: 'use',
    title: "",
    author: "",
    desc: "",
    keywords: "",
  })
  selectedGrade_listPermission = []//리스트 권한 설정 > 선택된 회원등급
  selectedGrade_readPermission = []//읽기 권한 설정 > 선택된 회원등급
  selectedGrade_writePermission = []//쓰기 권한 설정 > 선택된 회원등급
  selectedGrade_answerPermission = []//답변 권한 설정 > 선택된 회원등급
  selectedGrade_replyPermission = []//댓글 권한 설정 > 선택된 회원등급
  selectedGrade_oneOnePermission = []//1:1문의 답변 권한 설정 > 선택된 회원등급
  selectedGrade_secretPermission = []//비밀글 확인 권한 설정 > 선택된 회원등급
  selectedGrade_relationMember = []//관련회원 설정 > 선택된 회원등급
  selectedMembers = [] //작성 노출 제한에서 제외할 id
  grades:any = []
  readonly separatorKeysCodes: number[] = [ENTER];
  mode: string
  constructor(public api: APIService,
    public dialog: MatDialog,
    private global: Globals,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private router: Router) {
    iconRegistry.addSvgIcon('cancel', sanitizer.bypassSecurityTrustResourceUrl('assets/images/cancel-24px.svg'))

    this.mode = this.route.snapshot.queryParams['mode'] || 'reg';
    this.id = this.route.snapshot.params['id']

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  ngOnInit() {
    this.getGrades()

    if(this.mode != "reg"){
      this.getInfo()
      this.getCategories()
      this.getTemplateList()
    }
  }

  getCategories(){
    this.api.getBoardCategories(this.id).subscribe(response => {
      this.boardCategories = response
      response.forEach(element => {
        this.categoryTags.push(element.name)
      })
    })
  }

  async getGrades(){
    let response = await this.api.getGradesAsync()
    this.grades = response
  }

  getInfo(){
    this.api.getBoard(this.id).subscribe(response => {
      this.setInfo(response)
    })
  }

  setInfo(data){
    if(data){
      let defaults = data.settings.default_settings
      let auth = data.settings.auth_settings
      let functions = data.settings.function_settings
      let point = data.settings.point_settings
      let additional_field = data.settings.additional_field_settings
      let seo = data.settings.seo_settings

      this.useCategory = defaults.category_use

      this.grades.forEach(grade => {//특정 회원등급 세팅
        if(auth.list.specific_auth.use){
          auth.list.specific_auth.auth_codes.forEach(element => {
            if(grade.code == element){
              this.selectedGrade_listPermission.push(grade)
            }
          })
        }
        if(auth.read_auth.specific_auth.use){
          auth.read_auth.specific_auth.auth_codes.forEach(element => {
            if(grade.code == element){
              this.selectedGrade_readPermission.push(grade)
            }
          })
        }
        if(auth.write_auth.specific_auth.use){
          auth.write_auth.specific_auth.auth_codes.forEach(element => {
            if(grade.code == element){
              this.selectedGrade_writePermission.push(grade)
            }
          })
        }
        if(auth.answer_auth.specific_auth.use){
          auth.answer_auth.specific_auth.auth_codes.forEach(element => {
            if(grade.code == element){
              this.selectedGrade_answerPermission.push(grade)
            }
          })
        }
        if(auth.reply_auth.specific_auth.use){
          auth.reply_auth.specific_auth.auth_codes.forEach(element => {
            if(grade.code == element){
              this.selectedGrade_replyPermission.push(grade)
            }
          })
        }
        if(auth.one_on_one_auth.specific_auth.use){
          auth.one_on_one_auth.specific_auth.auth_codes.forEach(element => {
            if(grade.code == element){
              this.selectedGrade_oneOnePermission.push(grade)
            }
          })
        }
        if(auth.secret_content_read_auth.specific_auth.use){
          auth.secret_content_read_auth.specific_auth.auth_codes.forEach(element => {
            if(grade.code == element){
              this.selectedGrade_secretPermission.push(grade)
            }
          })
        }
        if(functions.related_users.use){
          functions.related_users.related_user_auth.forEach(element => {
            if(grade.code == element){
              this.selectedGrade_relationMember.push(grade)
            }
          })
        }
        if(functions.ban_words && functions.ban_words.list){
          let list = []
          functions.ban_words.list.forEach(word => {
            list.push({ name: word })
          })
          this.ban_word_list = list
        }
      })

      if(functions.except_hide_author){
        this.selectedMembers = functions.except_hide_author
      }

      this.form1 = this.formBuilder.group({
        id: data.id,
        board_name: data.name,
        url_prefix: data.url_prefix,
        mobile_board_name: data.mobile_name,
        category_use: defaults.category_use ? 'use' : 'notuse',
        start_number: defaults.start_idx,
      })
      this.form2 = this.formBuilder.group({
        list_permission: _.isEmpty(auth.list.auth) ? (auth.list.specific_auth.use ? "OTHER" : "ALL") : auth.list.auth[0],
        read_permission: _.isEmpty(auth.read_auth.auth) ? (auth.read_auth.specific_auth.use ? "OTHER" : "ALL") : auth.read_auth.auth[0],
        write_permission: _.isEmpty(auth.write_auth.auth) ? (auth.write_auth.specific_auth.use ? "OTHER" : "ALL") : auth.write_auth.auth[0],
        use_answer: auth.answer_use ? 'use' : 'notuse',
        answer_permission: _.isEmpty(auth.answer_auth.auth) ? (auth.answer_auth.specific_auth.use ? "OTHER" : "ALL") : auth.answer_auth.auth[0],
        use_reply: auth.reply_use ? 'use' : 'notuse',
        reply_permission: _.isEmpty(auth.reply_auth.auth) ? (auth.reply_auth.specific_auth.use ? "OTHER" : "ALL") : auth.reply_auth.auth[0],
        use_oneone: auth.one_on_one_use ? 'use' : 'notuse',
        reply_one_one_permission: _.isEmpty(auth.one_on_one_auth.auth) ? (auth.one_on_one_auth.specific_auth.use ? "OTHER" : "ALL") : auth.one_on_one_auth.auth[0],
        secret_permission: _.isEmpty(auth.secret_content_read_auth.auth) ? (auth.secret_content_read_auth.specific_auth.use ? "OTHER":"ALL") : auth.secret_content_read_auth.auth[0]
      })
      this.form3 = this.formBuilder.group({
        author_show_type: functions.show_author,
        hide_author: functions.show_author_mask,
        admin_show_type: functions.show_operator,
        use_do_not_modify: functions.board_content_modify_limit.use,
        do_not_modify_cnt: functions.board_content_modify_limit.has_reply_cnt,
        use_do_not_del: functions.board_content_delete_limit.use,
        do_not_del_cnt: functions.board_content_delete_limit.has_reply_cnt,
        board_del_option_reply: functions.answer_reply_delete_policy.with_reply ? 'with' : 'without',
        board_del_option_point: functions.board_content_delete_point_policy.use ? "use" : "notuse",
        board_del_option_point_lack: functions.board_content_delete_point_policy.not_enough_point,
        relation_member_use: functions.related_users.use ? "use" : "notuse",
        use_star_point: functions.star_point.use ? "use" : "notuse",
        write_board_star: functions.star_point.max_star+"",
        write_board_point: functions.star_point.max_star_point+"",
        use_board_recommend: functions.recommend_use ? "use" : "notuse",
        use_board_non_recommend: functions.unrecommend_use ? "use" : "notuse",
        use_board_view_count: functions.show_view_cnt ? "use" : "notuse",
        view_count_inc: functions.view_cnt_hit.plus,
        ip_duplicate: functions.view_cnt_hit.ip_limit,
        secret_board: functions.secret_content.type,
        secret_board_title: functions.secret_content.title_setting.user_title ? "origin" : "set",
        secret_board_title_template: functions.secret_content.title_setting.default_title,
        secret_reply: functions.secret_content.use_reply.type,
        secret_reply_content_set: functions.secret_content.use_reply.masking_string_use,
        secret_reply_content: functions.secret_content.use_reply.masking_string,
        new_icon_hour: functions.new_icon_during.amount,
        hot_icon_view_count: functions.hot_icon_condition.view_cnt,
        notice_count: functions.notice_settings.show_cnt_limit,
        notice_only_first: functions.notice_settings.first_page_only,
        notice_all_list: functions.notice_settings.inner_list,
        notice_icon_pc: functions.notice_settings.notice_icon_show_device.pc,
        notice_icon_mobile: functions.notice_settings.notice_icon_show_device.mobile,
        title_length_pc: functions.pc_title_length,
        title_length_mobile: functions.mobile_title_length,
        board_count_of_page_pc: functions.pc_page_size,
        board_count_of_page_mobile: functions.mobile_page_size,
        title_image_use: functions.representation_image.use ? "use" : "notuse",
        title_image_set_type: functions.representation_image.source,
        image_width: functions.representation_image.list_in_image_size.width,
        image_height: functions.representation_image.list_in_image_size.height,
        image_resize_crop_option: functions.representation_image.list_in_image_size.image_resize_crop_option,
        image_crop_horizontal_option: functions.representation_image.list_in_image_size.image_crop_horizontal_option,
        image_crop_vertical_option: functions.representation_image.list_in_image_size.image_crop_vertical_option,    
        list_content_use: functions.show_content_in_list_use ? "use" : "notuse",
        title_file_use: functions.show_file_in_list_use ? "use" : "notuse",
        editor_use: functions.use_editor ? "use" : "notuse",
        upload_file_use: functions.file_upload_settings.use ? "use" : "notuse",
        max_upload_file_size: functions.file_upload_settings.limit_file_size.amount,
        upload_file_show_image: functions.file_upload_settings.image_preview ? "use" : "notuse",
        image_resize: functions.thumbnail_settings.resize.amount,
        link: functions.link_settings.use ? "use" : "notuse",
        link_position: functions.link_settings.position,
        show_on_list: functions.thumbnail_settings.use ? "use" : "notuse",
        show_ip: functions.ip_show_settings.creator_ip_show,
        encrypt_ip: functions.ip_show_settings.mask_last_three_digit,
        board_spam: functions.anti_spam_settings.board,
        reply_spam: functions.anti_spam_settings.reply,
        content_alert_email_sending: functions.content_alert_email_sending && functions.content_alert_email_sending.use ? "use" : "notuse",
        email_template: functions.email_template && functions.email_template.use ? "use" : "notuse",
        email_template_target: functions.email_template && functions.email_template.template_idx ? functions.email_template.template_idx : 0,
        ban_word: functions.ban_words && functions.ban_words.use ? "use" : "notuse"
      })
      this.form4 = this.formBuilder.group({
        use_point: point.use ? 'use' : 'notuse',
        read_point: point.board_read_point,
        write_point: point.board_write_point,
        reply_point: point.reply_write_point,
        download_point: point.download_point,
      })
      this.form5 = this.formBuilder.group({
        field1: additional_field[0].use,
        field1_title: additional_field[0].title,
        field2: additional_field[1].use,
        field2_title: additional_field[1].title,
        field3: additional_field[2].use,
        field3_title: additional_field[2].title,
        field4: additional_field[3].use,
        field4_title: additional_field[3].title,
        field5: additional_field[4].use,
        field5_title: additional_field[4].title,
        field6: additional_field[5].use,
        field6_title: additional_field[5].title,
      })
      this.form6 = this.formBuilder.group({
        respectively: seo.use ? 'use' : 'notuse',
        title: seo.meta_title,
        author: seo.meta_author,
        desc: seo.meta_description,
        keywords: seo.meta_keyword,
      })

      if(this.mode == "info"){
        setTimeout(() => {
          this.form3.controls.hide_author.disable()
          this.form3.controls.use_star_point.disable()
          this.form3.controls.write_board_star.disable()
          this.form3.controls.write_board_point.disable()
        })
      }
    }
  }

  reg() {
    if((this.mode == "modify" && this.id != this.form1.value.id && !this.isIDChecked) || 
    (this.mode == "reg" && !this.isIDChecked)){
      this.snackBar.open("아이디 중복체크를 해주세요.", "확인", {
        duration: 2000,
      })
      return
    }
    let body = {
      id: this.form1.value.id,
      name: this.form1.value.board_name,
      mobile_name: this.form1.value.mobile_board_name,
      url_prefix: this.form1.value.url_prefix,
      settings: {
        //기본 설정
        default_settings: {
          category_use: this.useCategory,
          start_idx: Number(this.form1.value.start_number)
        },

        //권한 설정
        auth_settings: {
          list: {
            auth: this.form2.value.list_permission == "ALL" || this.form2.value.list_permission == "OTHER" ? [] : [this.form2.value.list_permission],
            specific_auth: {
              use: this.form2.value.list_permission == "OTHER" ? true : false,
              auth_codes: _.map(this.selectedGrade_listPermission, grade => {
                return _.propertyOf(grade)('code')
              })
            }
          },
          read_auth: {
            auth: this.form2.value.read_permission == "ALL" || this.form2.value.read_permission == "OTHER" ? [] : [this.form2.value.read_permission],
            specific_auth: {
              use: this.form2.value.read_permission == "OTHER" ? true : false,
              auth_codes: _.map(this.selectedGrade_readPermission, grade => {
                return _.propertyOf(grade)('code')
              })
            }
          },
          write_auth: {
            auth: this.form2.value.write_permission == "ALL" || this.form2.value.write_permission == "OTHER" ? [] : [this.form2.value.write_permission],
            specific_auth: {
              use: this.form2.value.write_permission == "OTHER" ? true : false,
              auth_codes: _.map(this.selectedGrade_writePermission, grade => {
                return _.propertyOf(grade)('code')
              })
            }
          },
          answer_use: this.form2.value.use_answer == "use" ? true : false,
          answer_auth: {
            auth: this.form2.value.answer_permission == "ALL" || this.form2.value.answer_permission =="OTHER" ? [] : [this.form2.value.answer_permission],
            specific_auth: {
              use: this.form2.value.answer_permission == "OTHER" ? true : false,
              auth_codes: _.map(this.selectedGrade_answerPermission, grade => {
                return _.propertyOf(grade)('code')
              })
            }
          },
          reply_use: this.form2.value.use_reply == "use" ? true : false,
          reply_auth: {
            auth: this.form2.value.reply_permission == "ALL"|| this.form2.value.reply_permission == "OTHER" ?  [] : [this.form2.value.reply_permission],
            specific_auth: {
              use: this.form2.value.reply_permission == "OTHER" ? true : false,
              auth_codes: _.map(this.selectedGrade_replyPermission, grade => {
                return _.propertyOf(grade)('code')
              })
            }
          },
          one_on_one_use: this.form2.value.use_oneone == "use" ? true : false,
          one_on_one_auth: {
            auth: this.form2.value.reply_one_one_permission == "ALL" || this.form2.value.reply_one_one_permission == "OTHER" ? [] : [this.form2.value.reply_one_one_permission],
            specific_auth: {
              use: this.form2.value.reply_one_one_permission == "OTHER" ? true : false,
              auth_codes: _.map(this.selectedGrade_oneOnePermission, grade => {
                return _.propertyOf(grade)('code')
              })
            }
          },
          secret_content_read_auth: {
            auth: this.form2.value.secret_permission == "ALL" || this.form2.value.secret_permission =="OTHER" ? [] : [this.form2.value.secret_permission],
            specific_auth: {
              use: this.form2.value.secret_permission == "OTHER" ? true : false,
              auth_codes: _.map(this.selectedGrade_secretPermission, grade => {
                return _.propertyOf(grade)('code')
              })
            }
          }
        },

        //기능 설정
        function_settings: {
          show_author: this.form3.value.author_show_type,
          show_author_mask: this.form3.value.hide_author,
          except_hide_author: this.selectedMembers,
          show_operator: this.form3.value.admin_show_type,
          board_content_modify_limit: {
            use: this.form3.value.use_do_not_modify,
            has_reply_cnt: Number(this.form3.value.do_not_modify_cnt)
          },
          board_content_delete_limit: {
            use: this.form3.value.use_do_not_del,
            has_reply_cnt: Number(this.form3.value.do_not_del_cnt)
          },
          answer_reply_delete_policy: {
            with_reply: this.form3.value.board_del_option_reply == "with" ? true : false
          },
          board_content_delete_point_policy: {
            use: this.form3.value.board_del_option_point == "use" ? true : false,
            not_enough_point: this.form3.value.board_del_option_point_lack
          },
          related_users: {
            use: this.form3.value.relation_member_use == "use" ? true : false,
            related_user_auth: _.map(this.selectedGrade_relationMember, grade => {
              return _.propertyOf(grade)('code')
            })
          },
          star_point: {
            use: this.form3.value.use_star_point == "use" ? true : false,
            max_star: Number(this.form3.value.write_board_star),
            max_star_point: Number(this.form3.value.write_board_point)
          },
          recommend_use: this.form3.value.use_board_recommend == "use" ? true : false,
          unrecommend_use: this.form3.value.use_board_non_recommend == "use" ? true : false,
          show_view_cnt: this.form3.value.use_board_view_count == "use" ? true : false,
          view_cnt_hit: {
            plus: Number(this.form3.value.view_count_inc),
            ip_limit: this.form3.value.ip_duplicate
          },
          secret_content: {
            type: this.form3.value.secret_board,
            title_setting: {
              user_title: this.form3.value.secret_board_title == "origin" ? true : false,
              default_title: this.form3.value.secret_board_title_template
            },
            use_reply: {
              type: this.form3.value.secret_reply,
              masking_string_use: this.form3.value.secret_reply_content_set,
              masking_string: this.form3.value.secret_reply_content
            }
          },
          new_icon_during: {
            unit: 'hour',
            amount: Number(this.form3.value.new_icon_hour)
          },
          hot_icon_condition: {
            view_cnt: Number(this.form3.value.hot_icon_view_count)
          },
          notice_settings: {
            show_cnt_limit: Number(this.form3.value.notice_count),
            first_page_only: this.form3.value.notice_only_first,
            inner_list: this.form3.value.notice_all_list,
            notice_icon_show_device: {
              pc: this.form3.value.notice_icon_pc,
              mobile: this.form3.value.notice_icon_mobile
            }
          },
          pc_title_length: Number(this.form3.value.title_length_pc),
          mobile_title_length: Number(this.form3.value.title_length_mobile),
          pc_page_size: Number(this.form3.value.board_count_of_page_pc),
          mobile_page_size: Number(this.form3.value.board_count_of_page_mobile),
          representation_image: {
            use: this.form3.value.title_image_use == "use" ? true : false,
            source: this.form3.value.title_image_set_type,
            list_in_image_size: {
              width: Number(this.form3.value.image_width),
              height: Number(this.form3.value.image_height),
              image_resize_crop_option: this.form3.value.image_resize_crop_option,
              image_crop_horizontal_option: this.form3.value.image_crop_horizontal_option,
              image_crop_vertical_option: this.form3.value.image_crop_vertical_option
            }
          },
          show_content_in_list_use: this.form3.value.list_content_use == "use"? true : false,
          show_file_in_list_use: this.form3.value.title_file_use == "use"? true : false,
          use_editor: this.form3.value.editor_use == "use" ? true : false,
          file_upload_settings: {
            use: this.form3.value.upload_file_use == "use" ? true : false,
            limit_file_size: {
              unit: "mb",
              amount: Number(this.form3.value.max_upload_file_size)
            },
            limit_file_cnt: 10,
            image_preview: this.form3.value.upload_file_show_image == "use" ? true : false
          },
          thumbnail_settings: {
            use: this.form3.value.show_on_list == "use" ? true : false,
            resize: {
              unit: "px",
              amount: Number(this.form3.value.image_resize)
            }
          },
          link_settings: {
            use: this.form3.value.link == "use" ? true : false,
            position: this.form3.value.link_position
          },
          ip_show_settings: {
            creator_ip_show: this.form3.value.show_ip,
            mask_last_three_digit: this.form3.value.encrypt_ip
          },
          anti_spam_settings: {
            board: this.form3.value.board_spam,
            reply: this.form3.value.reply_spam
          },
          content_alert_email_sending: {
            use: this.form3.value.content_alert_email_sending  == "use" ? true : false,
          },
          email_template: {
            use: this.form3.value.email_template  == "use" ? true : false,
            template_idx: this.form3.value.email_template_target ? this.form3.value.email_template_target: 0
          },
          ban_words: {
            use: this.form3.value.ban_word  == "use" ? true : false,
            list: _.map(this.ban_word_list,(item)=>{return item.name})
          }
        },

        //포인트 설정
        point_settings: {
          use: this.form4.value.use_point == "use" ? true : false,
          board_read_point: Number(this.form4.value.read_point),
          board_write_point: Number(this.form4.value.write_point),
          reply_write_point: Number(this.form4.value.reply_point),
          download_point: Number(this.form4.value.download_point)
        },

        //여분필드 설정
        additional_field_settings: [
          {
            use: this.form5.value.field1,
            label: '여분필드 1',
            title: this.form5.value.field1_title
          },
          {
            use: this.form5.value.field2,
            label: '여분필드 2',
            title: this.form5.value.field2_title
          },
          {
            use: this.form5.value.field3,
            label: '여분필드 3',
            title: this.form5.value.field3_title
          },
          {
            use: this.form5.value.field4,
            label: '여분필드 4',
            title: this.form5.value.field4_title
          },
          {
            use: this.form5.value.field5,
            label: '여분필드 5',
            title: this.form5.value.field5_title
          },
          {
            use: this.form5.value.field6,
            label: '여분필드 6',
            title: this.form5.value.field6_title
          }
        ],

        //seo 설정
        seo_settings: {
          use: this.form6.value.respectively == "use" ? true : false,
          meta_title: this.form6.value.title,
          meta_author: this.form6.value.author,
          meta_description: this.form6.value.desc,
          meta_keyword: this.form6.value.keywords
        }
      }
    }

    // console.log(body)
    if(this.mode == "reg"){
      this.api.regBoard(body).subscribe(response => {
        if(this.useCategory){ 
          this.categoryTags.forEach(value => {
            this.api.regBoardCategory(response.id, {name: value}).subscribe()
          })
        }
        const dialogRef = this.dialog.open(DefaultDialogComponent)
        dialogRef.componentInstance.data = { content: '등록되었습니다.', closeText: '확인' }
        dialogRef.afterClosed().subscribe(response => {
          this.router.navigate(['/board/board-management'])
        })
      })
    }else{
      this.api.modifyBoard(this.id, body).subscribe(response => {
        if(this.useCategory){
          let added = _.difference(this.categoryTags, _.map(this.boardCategories, grade => {
            return _.propertyOf(grade)('name')
          }))
          if(!_.isEmpty(added)){//추가 된 카테고리 등록
            added.forEach(value => {
              this.api.regBoardCategory(this.id, {name: value}).subscribe()
            })
          }
          
          let deleted = _.difference(_.map(this.boardCategories, grade => {
            return _.propertyOf(grade)('name')
          }), this.categoryTags)
          if(!_.isEmpty(deleted)){//삭제 된 카테고리 제거
            deleted.forEach(value => {
              let grade = _.find(this.boardCategories, function(grade){ return grade.name == value; });
              this.api.delBoardCategory(this.id, grade.code).subscribe()
            })
          }
        }
        const dialogRef = this.dialog.open(DefaultDialogComponent)
        dialogRef.componentInstance.data = { content: '저장되었습니다.', closeText: '확인' }
        dialogRef.afterClosed().subscribe(response => {
          this.router.navigate(['/board',  this.id], { queryParams: { mode: 'info' } })
        })
      })
    }
  }

  mod(){
    this.router.navigate(['/board', this.id], { queryParams: { mode: 'modify' } })
  }

  onIDChange(id) {
    this.isIDChecked = false
  }

  idCheck() {
    if (this.form1.value.id && this.form1.value.id.length > 0) {
      this.api.boardDetail(this.form1.value.id, "self").subscribe(response => {
        const dialogRef = this.dialog.open(DefaultDialogComponent)
        dialogRef.componentInstance.data = { content: '이미 있습니다.', closeText: '확인' }
        this.isIDChecked = false
      }, error => {
        const dialogRef = this.dialog.open(DefaultDialogComponent)
        dialogRef.componentInstance.data = { content: '사용가능합니다.', closeText: '확인' }
        this.isIDChecked = true
      })
    } else {
      this.snackBar.open("아이디를 입력해주세요.", "확인", {
        duration: 2000,
      })
    }
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView()
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.categoryTags.push(value.trim());
    }

    if (input) {
      input.value = '';
    }
  }

  remove(keyword): void {
    const index = this.categoryTags.indexOf(keyword);

    if (index >= 0) {
      this.categoryTags.splice(index, 1);
    }
  }

  //////////////////////////////////////////
  //회원등급버튼 클릭
  selectGrade(from, datas) {//리스트권한
    if (from == "listPermission") {

    } else if (from == "readPermission") {

    } else if (from == "writePermission") {

    } else if (from == "answerPermission") {

    } else if (from == "replyPermission") {

    } else if (from == "oneOnePermission") {

    } else if (from == "secretPermission") {

    } else if (from == "relationMember") {

    }

    let dialogRef = this.dialog.open(SelectGradeDialogComponent, { height: '600px', width: '600px' })
    dialogRef.componentInstance.data = { grades: datas }
    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        if (from == "listPermission") {
          response.forEach(element => {
            this.selectedGrade_listPermission.push(element)
          })
        } else if (from == "readPermission") {
          response.forEach(element => {
            this.selectedGrade_readPermission.push(element)
          })
        } else if (from == "writePermission") {
          response.forEach(element => {
            this.selectedGrade_writePermission.push(element)
          })
        } else if (from == "answerPermission") {
          response.forEach(element => {
            this.selectedGrade_answerPermission.push(element)
          })
        } else if (from == "replyPermission") {
          response.forEach(element => {
            this.selectedGrade_replyPermission.push(element)
          })
        } else if (from == "oneOnePermission") {
          response.forEach(element => {
            this.selectedGrade_oneOnePermission.push(element)
          })
        } else if (from == "secretPermission") {
          response.forEach(element => {
            this.selectedGrade_secretPermission.push(element)
          })
        } else if (from == "relationMember") {
          response.forEach(element => {
            this.selectedGrade_relationMember.push(element)
          })
        }
      }
    })
  }
  //////////////////////////////////////////
  removeGrade(from, grade) {
    if (from == "listPermission") {
      this.selectedGrade_listPermission = _.filter(this.selectedGrade_listPermission, function (value) {
        return value.name != grade
      })
    } else if (from == "readPermission") {
      this.selectedGrade_readPermission = _.filter(this.selectedGrade_readPermission, function (value) {
        return value.name != grade
      })
    } else if (from == "writePermission") {
      this.selectedGrade_writePermission = _.filter(this.selectedGrade_writePermission, function (value) {
        return value.name != grade
      })
    } else if (from == "answerPermission") {
      this.selectedGrade_answerPermission = _.filter(this.selectedGrade_answerPermission, function (value) {
        return value.name != grade
      })
    } else if (from == "replyPermission") {
      this.selectedGrade_replyPermission = _.filter(this.selectedGrade_replyPermission, function (value) {
        return value.name != grade
      })
    } else if (from == "oneOnePermission") {
      this.selectedGrade_oneOnePermission = _.filter(this.selectedGrade_oneOnePermission, function (value) {
        return value.name != grade
      })
    } else if (from == "secretPermission") {
      this.selectedGrade_secretPermission = _.filter(this.selectedGrade_secretPermission, function (value) {
        return value.name != grade
      })
    } else if (from == "relationMember") {
      this.selectedGrade_relationMember = _.filter(this.selectedGrade_relationMember, function (value) {
        return value.name != grade
      })
    }
  }

  async getTemplateList(){
    let boardList = await this.api.getTemplateList(1,9999,'board',this.id).toPromise()
    let etcList = await this.api.getTemplateList(1,9999,'etc').toPromise()
    for (let data of boardList.data) {
      this.templateList.push(data)
    }
    for (let data of etcList.data){
      this.templateList.push(data)
    }
    
  }
  selectMember(){
    let dialogRef = this.dialog.open(SelectMemberDialogComponent, { height: '600px', width: '600px' })
    dialogRef.componentInstance.data = { members: this.selectedMembers }
    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        if(this.selectedMembers == null){
          this.selectedMembers = []
        }
        response.forEach(element => {
          let exist = _.find(this.selectedMembers,(item)=>{
            return item == element.id
          })
          if(!exist){
            this.selectedMembers.push(element.id)
          }
        })
      }
    })
  }
  removeMember(id){
    this.selectedMembers = _.filter(this.selectedMembers, (member) => {
      return member != id
    })
  }
}
