import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatTableDataSource, MatDialog, PageEvent, MatPaginator } from '@angular/material';
import { APIService } from 'app/services/api.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import * as _ from 'underscore'

@Component({
  selector: 'app-member-management',
  templateUrl: './member-management.component.html',
  styleUrls: ['./member-management.component.scss']
})
export class MemberManagementComponent implements OnInit {
  displayedColumns: string[] = ['auth_name', 'id', 'name', 'nick', 'phone', 'email', 'status', 'is_decoy', 'created_at', '비고']
  dataSource: any = []
  totalCnt
  size: number = 10
  currentPage: number = 1
  form: FormGroup
  grades = []
  
  constructor(
    public api: APIService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private router: Router,
  ) { }

  ngOnInit() {
    this.getGrades()

    this.form = this.formBuilder.group({
      memberType: '',
      searchType: '',
      searchKeyword: ''
    })

    this.getUserSetting()
  }

  getUserSetting(){
    this.api.getUserSetting().subscribe(response => {
      //사용하지 않을 키를 추출
      let exclusionKeys = []
      _.each(response, (value, key) => {
        if(value.use == false){
          exclusionKeys.push(key)
        }
      })
      //헤더에서 제거
      this.displayedColumns = _.without(this.displayedColumns, ...exclusionKeys)
    })
  }

  getGrades(){
    this.api.getGrades().subscribe(response => {
      this.grades = response
      this.getList()
    })
  }

  reg(){
    this.router.navigate(['/member', 'reg-member'], { queryParams: { mode: 'reg' } })
  }

  showInfo(id){
    this.router.navigate(['/member', id], { queryParams: { mode: 'info' } })
  }

  refreshList(){
    this.currentPage = 1
    this.dataSource = []
    this.totalCnt = 0

    this.getList()
  }

  getList() {
    let auth: any = this.form.value.memberType == "" ? undefined : [this.form.value.memberType]
    this.api.getUsers(this.currentPage, this.size, this.form.value.searchType, this.form.value.searchKeyword, auth).subscribe(response => {
      this.dataSource = _.map(response.data, (user) => {
        let grade = _.find(this.grades, (grade)=>{ return grade.code == user.auth_code })
        user.auth_code = grade.name
        return user
      })
      this.totalCnt = response.pagination.total_cnt
    })
  }

  search() {
    if(this.form.valid){
      this.getList()
    }
  }

  onPaginateChange(event) {
    this.currentPage = event.pageIndex + 1
    this.getList()
  }
}
