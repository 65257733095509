import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, ValidatorFn, ValidationErrors } from '@angular/forms';
import { APIService } from 'app/services/api.service';
import { MatDialog, MatSnackBar, NativeDateAdapter, DateAdapter } from '@angular/material';
import { Globals } from 'app/services/globals';
import { ActivatedRoute, Router } from '@angular/router';
import { ModifyPasswordDialogComponent } from 'app/dialogs/modify-password-dialog/modify-password-dialog.component';
import * as _moment from 'moment';
import FroalaEditor from 'froala-editor';
import { FroalaOptions } from 'app/models/froala-options';
import { DefaultDialogComponent } from 'app/dialogs/default-dialog/default-dialog.component';
import { stat } from 'fs';

const moment = _moment
export const MY_FORMATS = {
  parse: {
    dateInput: {month: 'short', year: 'numeric', day: 'numeric'}
  },
  display: {
    dateInput: 'input',
    monthYearLabel: {year: 'numeric', month: 'short'},
    dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
    monthYearA11yLabel: {year: 'numeric', month: 'long'},
  },
};

export class MyDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: Object): string {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    return year + '-' + this._to2digit(month) + '-' + this._to2digit(day)
  }

  private _to2digit(n: number) {
      return ('00' + n).slice(-2);
  } 
}

@Component({
  selector: 'app-popup-layer-detail',
  templateUrl: './popup-layer-detail.component.html',
  styleUrls: ['./popup-layer-detail.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MyDateAdapter},
    {provide: MY_FORMATS, useValue: MY_FORMATS},
  ],
})
export class PopupLayerDetailComponent implements OnInit {
  id: string
  selectedImage
  selectedFile
  form: FormGroup = this.formBuilder.group({
    type: '',
    startDate: '',
    startTime: {hour: 0, minute: 0},
    endDate: '',
    endTime: {hour: 0, minute: 0},
    left: '',
    top: '',
    width: '',
    height: '',
    title: '',
    content: ''
  })
  mode: string
  editorOptions: Object
  
  constructor(public api: APIService,
    public dialog: MatDialog,
    private global: Globals,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private router: Router) {
      this.mode = this.route.snapshot.queryParams['mode'] || 'info';
      this.id = this.route.snapshot.params['id']

      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      }

      var token = localStorage.getItem('id_token')
      if (token != null && token != undefined && token.length > 0) {
        this.editorOptions = new FroalaOptions(token, "/api/v1/files/image", this.global, 250)
      }else{
        this.editorOptions = {
          attribution: false,
          language: 'ko',
          height: 250,
          charCounterCount : false,
          videoResponsive: true,
          placeholderText: "이미지는 업로드 할 수 없습니다. (토큰없음)",
          key: global.froalaKey,
        }
      }
    }

  ngOnInit() {
    if(this.mode == "info" || this.mode == "modify"){
      this.getInfo()
    }
  }

  setInfo(data){
    if(data){
      let startDate = moment(data.start_date)
      let endDate = moment(data.end_date)

      this.form = this.formBuilder.group({
        type: data.device_type,
        startDate: startDate.format("YYYY-MM-DD"),
        startTime: {hour: Number(startDate.format("HH")), minute: Number(startDate.format("mm"))},
        endDate: endDate.format("YYYY-MM-DD"),
        endTime: {hour: Number(endDate.format("HH")), minute: Number(endDate.format("mm"))},
        left: data.position.left,
        top: data.position.top,
        width: data.position.width,
        height: data.position.height,
        title: data.title,
        content: data.content
      })

      if(this.mode == "info"){
        setTimeout(() => {
          this.form.controls.type.disable()
        })
      }
    }
  }

  getInfo(){
    this.api.getPopup(this.id).subscribe(response => {
      this.setInfo(response)
    })
  }

  to2digit(n: number) {
    return ('00' + n).slice(-2);
  }

  save(){
    let body = {
      title: this.form.value.title,
      content: this.form.value.content,
      device_type: this.form.value.type,
      start_date: `${this.form.value.startDate} ${this.to2digit(Number(this.form.value.startTime.hour))}:${this.to2digit(Number(this.form.value.startTime.minute))}:00`,
      end_date: `${this.form.value.endDate} ${this.to2digit(Number(this.form.value.endTime.hour))}:${this.to2digit(Number(this.form.value.endTime.minute))}:00`,
      position: {
        left: Number(this.form.value.left),
        top: Number(this.form.value.top),
        width: Number(this.form.value.width),
        height: Number(this.form.value.height)}
    }
    
    if(isNaN(Number(this.form.value.left)) || isNaN(Number(this.form.value.top)) ||
    isNaN(Number(this.form.value.width)) || isNaN(Number(this.form.value.height))){
      this.snackBar.open("위취와 크기는 숫자만 입력해주세요", "확인", {
        duration: 2000,
      })
      return
    }
    if(body.position.width > 600){
      this.snackBar.open("팝업 넓이는 600px을 넘을 수 없습니다.", "확인", {
        duration: 2000,
      })
      return
    }

    if(this.mode == "reg"){
      this.api.regPopup(body).subscribe(response => {
        const dialogRef = this.dialog.open(DefaultDialogComponent)
          dialogRef.componentInstance.data = { content: '등록되었습니다.', closeText: '확인' }
          dialogRef.afterClosed().subscribe(response => {
            this.router.navigate(['/popup'])
          })
      })
    }else{
      this.api.modifyPopup(this.id, body).subscribe(response => {
        const dialogRef = this.dialog.open(DefaultDialogComponent)
          dialogRef.componentInstance.data = { content: '저장되었습니다.', closeText: '확인' }
          dialogRef.afterClosed().subscribe(response => {
            this.router.navigate(['/popup',  this.id], { queryParams: { mode: 'info' } })
          })
      })
    }
  }

  mod(){
    this.router.navigate(['/popup', this.id], { queryParams: { mode: 'modify' } })
  }

  goList(){
    this.router.navigate(["/popup"])
  }

  startDateChange(date){
    this.form.controls.startDate.setValue(moment(date).format('YYYY-MM-DD'))
  }

  endDateChange(date){
    this.form.controls.endDate.setValue(moment(date).format('YYYY-MM-DD'))
  }
}