import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { APIService } from 'app/services/api.service';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { DefaultDialogComponent } from 'app/dialogs/default-dialog/default-dialog.component';

@Component({
  selector: 'app-board-management',
  templateUrl: './board-management.component.html',
  styleUrls: ['./board-management.component.scss']
})
export class BoardManagementComponent implements OnInit {
  displayedColumns: string[] = ['1', '2', '3', '4', '5', '6', '7', '8', '9']
  dataSource: any
  totalCnt
  size: number = 10
  currentPage: number = 1
  form: FormGroup = this.formBuilder.group({
    type: '',
    searchKeyword: ''
  })
  
  constructor(
    public api: APIService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private router: Router,
  ) { }

  ngOnInit() {
    this.getList()
  }

  reg(){
    this.router.navigate(['/board', 'reg-board'], { queryParams: { mode: 'reg' } })
  }

  showInfo(data){
    this.router.navigate(['/board', data.id], { queryParams: { mode: 'info' } })
  }

  showBoard(data){
    console.log(data)
    if(data.id == "faq"){
      window.open("https://test.taeshincrime.com/counselling/" + data.id, "_blank")
    }else if(data.id == "media" || data.id == "youtube"){
      window.open("https://test.taeshincrime.com/taeshin/" + data.id, "_blank")
    }else{
      window.open("https://test.taeshincrime.com/" + data.id, "_blank")
    }
  }

  manageBoard(data){
    this.router.navigate(['/board-content'], { queryParams: { id: data.id }})
  }

  del(data){
    const dialogRef = this.dialog.open(DefaultDialogComponent)
    dialogRef.componentInstance.data = { content: '삭제 하시겠습니까?', activeText: '삭제', closeText: '취소' }
    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        this.api.delBoard(data.id).subscribe(response => {
          if (response) {
            this.refreshList()
          }
        })
      }
    })
  }

  refreshList(){
    this.currentPage = 1
    this.dataSource = []
    this.totalCnt = 0

    this.getList()
  }

  getList() {
    this.api.getBoards(this.currentPage, this.size, this.form.value.type, this.form.value.searchKeyword).subscribe(response => {
      for (let property of response.data) {
        property.no = (this.size * (this.currentPage - 1)) + response.data.indexOf(property) + 1
      }
      this.dataSource = response.data
      this.totalCnt = response.pagination.total_cnt
    })
    // this.dataSource = [{ 
    //   id: 'lux',
    //   name: '럭셔리',
    //   total_cnt: 32455,
    //   new_cnt: 32,
    //   no_answer: 2,
    // },{ 
    //   id: 'golf',
    //   name: '골프',
    //   total_cnt: 312,
    //   new_cnt: 2,
    //   no_answer: 0,
    // }]
    // this.totalCnt = 2
  }

  search() {
    if(this.form.valid){
      this.getList()
    }
  }

  onPaginateChange(event) {
    this.currentPage = event.pageIndex + 1
    this.getList()
  }
}

