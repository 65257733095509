import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, Validators, FormBuilder, ValidatorFn, ValidationErrors } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material';
import { DefaultDialogComponent } from '../default-dialog/default-dialog.component';
import { Globals } from 'app/services/globals';
import { APIService } from 'app/services/api.service';

@Component({
  selector: 'app-modify-password-dialog',
  templateUrl: './modify-password-dialog.component.html',
  styleUrls: ['./modify-password-dialog.component.scss']
})
export class ModifyPasswordDialogComponent implements OnInit {
  form: FormGroup = this.formBuiler.group({
    old_pw: '',
    //pw: ['', [Validators.required, Validators.minLength(8)]],
    pw: ['', Validators.required],
    pw2: ['', Validators.required]
  }, { validator: passwordMatchValidator })
  isShow

  @Input() data:{
    id: string
  }
  
  constructor(
    private api: APIService,
    private formBuiler: FormBuilder,
    private dlg: MatDialog,
    public global: Globals,
    private dialog:MatDialogRef<ModifyPasswordDialogComponent>) {
  }

  ngOnInit() {
    this.isShow = this.global.user.auth <= AUTH.MANAGER
  }

  save() {
    if(this.form.valid){
      if(this.isShow){//운영자 미만
        if(this.form.value.old_pw.length > 0){
          
        }else{
          let dialogRef = this.dlg.open(DefaultDialogComponent)
          dialogRef.componentInstance.data = { content: "기존 비밀번호를 입력해주세요.", closeText: '확인' }
        }
      }else{//운영자 이상
        this.api.modifyPassword(this.data.id, {password: this.form.value.pw, new_password: this.form.value.pw2}).subscribe(response => {
          const dialogRef = this.dlg.open(DefaultDialogComponent)
          dialogRef.componentInstance.data = { content: '수정되었습니다.', closeText: '확인' }
          dialogRef.afterClosed().subscribe(response => {
            this.dialog.close()
          })
        })
      }
    }else{
      let dialogRef = this.dlg.open(DefaultDialogComponent)
      dialogRef.componentInstance.data = { content: "입력값을 확인해주세요.", closeText: '확인' }
    }
  }

  get pw() { return this.form.get('pw'); }
  get pw2() { return this.form.get('pw2'); }

  onPasswordInput() {
    if (this.form.hasError('passwordMismatch'))
      this.pw2.setErrors([{'passwordMismatch': true}])
    else if(this.pw2){
      this.pw2.setErrors(null)
    }
  }
}

export const passwordMatchValidator: ValidatorFn = (formGroup: FormGroup): ValidationErrors | null => {
  if (formGroup.get('pw').value === formGroup.get('pw2').value)
    return null;
  else
    return {passwordMismatch: true};
}
