import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { APIService } from 'app/services/api.service';
import { MatDialog, MatSnackBar, MatIconRegistry } from '@angular/material';
import { Globals } from 'app/services/globals';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { FroalaOptions } from 'app/models/froala-options';
import { SelectMemberDialogComponent } from 'app/dialogs/select-member-dialog/select-member-dialog.component';
import * as _ from 'underscore';
import { NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import {Location} from '@angular/common';
import { DefaultDialogComponent } from 'app/dialogs/default-dialog/default-dialog.component';

@Component({
  selector: 'app-board-content-detail',
  templateUrl: './board-content-detail.component.html',
  styleUrls: ['./board-content-detail.component.scss']
})
export class BoardContentDetailComponent implements OnInit {
  categories = []
  selectedMembers = []
  form1: FormGroup = this.formBuilder.group({
    notice: false,
    secret: false,
    name: "",
    pw: "",
    phone: "",
    email: "",
    category: "",
    title: "",
    useHTML: "use",
    content: "",
    star: "5",
    link: "",
    view_count: "",
    agree: false,
    privacy_content: "",
    created_at: "",
    author: {name: "", grade: "", ip: ""},
    recommend_count: 0,
    non_recommend_count: 0
  })
  form2: FormGroup = this.formBuilder.group({
    creator_name:"",
    creator_auth:"",
    status: "pending",
    title: "",
    content: ""
  })
  form3:FormGroup = this.formBuilder.group({
    creator_name: "",
    pw: "",
    secret: false,
    content: "",
    agree: false,
    privacy: "",
  })
  answerIdx:string
  mode: string
  answerMode = "info"
  editorOptions: Object
  boardId
  idx: string
  pointOfStar = 1 //게시판 등록 시 설정한 별 하나에 대한 포인트
  maxFileSize
  selectedFiles = []
  fieldList:Array<any>=[]
  imageUploadCategory = "image"
  canWriteNoneUser:boolean = false
  endOfList = false
  page = 1
  replies = []
  settings

  constructor(public api: APIService,
    public dialog: MatDialog,
    private global: Globals,
    private location: Location,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private ratingConfig: NgbRatingConfig,
    private router: Router) { 
      ratingConfig.max = 5
      ratingConfig.readonly = true

      this.mode = this.route.snapshot.queryParams['mode'] || 'info';
      this.answerMode = this.route.snapshot.queryParams['answerMode'] || 'info';
      this.boardId = this.route.snapshot.queryParams['boardId']
      console.log("board id = " + this.boardId)
      this.idx = this.route.snapshot.params['id']
      console.log("idx = " + this.idx)
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      }

      var token = localStorage.getItem('id_token')
      if (token != null && token != undefined && token.length > 0) {
        this.editorOptions = new FroalaOptions(token, "/api/v1/files/" + this.imageUploadCategory, this.global, 250)
      }else{
        this.editorOptions = {
          attribution: false,
          language: 'ko',
          height: 250,
          charCounterCount : false,
          videoResponsive: true,
          placeholderText: "이미지는 업로드 할 수 없습니다. (토큰없음)",
          key: global.froalaKey,
        }
      }
    }

  ngOnInit() {
    this.getCategories()
    this.maxFileSize = 1

    if(this.mode == "info" || this.mode == "modify"){
      this.getInfo()
      this.getAnswer()
      this.getReplies()
    }
    
    if(this.mode == 'reg'){
      this.getBoard()
    }
    
    this.getPrivacyContent()
  }

  async getCategories(){
    this.api.getBoardCategories(this.boardId).subscribe(response=>{
      this.categories=response
    })
  }

  getBoard() {
    this.api.getBoardDetail(this.boardId).subscribe(response => {
      let i=1;
      for(let field of response.settings.additional_field_settings){
        if(field.use){
          this.fieldList.push({
            title:field.title,
            name:'field'+i
          })
          this.form1.addControl('field'+i, new FormControl(''));
          i++
        }
      }
    })
  }

  getPrivacyContent(){
    this.api.getTermCategory('non_user_privacy_collect').subscribe(response=>{
      if(response){
        this.form1.controls['privacy_content'].setValue(response.content)
      }
    })
  }

  setInfo(data){
    if(data){
      let obj = {
        notice: data.is_notice,
        secret: data.is_secret,
        pw: "",
        name: data.name || "",
        phone: data.phone,
        email: data.email,
        category: data.board_category == null ? "" : data.board_category.code == null ? "" : data.board_category.code,
        title: data.title,
        content: data.content,
        star: data.star_point,
        view_count: data.view_cnt,
        link: data.link,
        author: {name: data.creator_info, grade: "", ip: data.ip_address},
        agree: data.terms_agree,
        created_at: data.created_at,
        recommend_count: data.recommend_cnt,
        non_recommend_count: data.unrecommend_cnt
      }
      for(let key in obj){
        this.form1.controls[key].setValue(obj[key])
      }
      this.selectedMembers=data.related_users

      if(data.attach_files){
        for(let file of data.attach_files){
          file.name = file.file_name
          this.selectedFiles.push(file)
        }
      }

      if(this.fieldList.length > 0 && data.additional_fields != null && data.additional_fields.length > 0){
        for(let field of this.fieldList){
          let targetfield = _.findWhere(data.additional_fields,{title:field.title})
          this.form1.controls[field.name].setValue(targetfield.content)
        }
      }
      if(this.mode == "info"){
        setTimeout(() => {
          this.form1.controls.category.disable()
        })
      }
    }
  }

  getReplies(){
    this.api.getReplies(this.idx, this.page).subscribe(response => {
      // if (this.replies.length == 0) {
      //   this.replies = response.data
      // } else {
        
      // }
      for (let reply of response.data) {
        reply['toggle'] = false
        reply['modify']=false
        reply['form'] = this.formBuilder.group({
          creator_name: "",
          pw: "",
          secret: false,
          content: "",
          agree: false,
          privacy: "",
        })
        for(let reply2 of reply.replies){
          reply2['toggle'] = false
          reply2['modify']=false
        reply2['form'] = this.formBuilder.group({
          creator_name: "",
          pw: "",
          secret: false,
          content: "",
          agree: false,
          privacy: "",
        })
        }
        this.replies.push(reply)
      }
      
      let total = response.pagination.total_cnt
      let size = response.pagination.size
      if (size * this.page >= total) {
        this.endOfList = true
      }
    })
  }

  moreReplies() {
    if (!this.endOfList) {
      this.page += 1
      this.getReplies()
    }
  }

  getAnswer(){
    this.api.getAnswer(this.boardId,this.idx).subscribe(data=>{
      if(data){
        this.answerIdx = data.idx
        this.form2 = this.formBuilder.group({
          creator_name: data.creator_name,
          creator_auth: data.creator_auth,
          creator_id: data.creator_id,
          status: data.status,
          title: data.title,
          content: data.content
        })
  
        if(this.mode == "info" && this.answerMode != "answer"){
          setTimeout(() => {
            this.form2.controls.status.disable()
          })
        }
      }
    })
    
  }

  getInfo(){
    this.api.boardContentDetail(this.boardId, this.idx).subscribe(response => {
      if (response.board_settings.function_settings.star_point.max_star_point == 10) {
        this.pointOfStar = 2
      }
      this.settings = response.board_settings

      let i=1;
      for(let field of response.board_settings.additional_field_settings){
        if(field.use){
          this.fieldList.push({
            title:field.title,
            name:'field'+i
          })
          this.form1.addControl('field'+i, new FormControl(''));
          i++
        }
      }

      this.setInfo(response)
    })
  }

  selectMember(){
    let dialogRef = this.dialog.open(SelectMemberDialogComponent, {height: '600px', width: '600px'})
    dialogRef.componentInstance.data = { members: this.selectedMembers }
    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        if(this.selectedMembers == null){
          this.selectedMembers = []
        }
        response.forEach(element => {
          this.selectedMembers.push(element)
        })
      }
    })
  }

  onFilesAdded(files) {
    let exist = false
    if(files.length > 10){
      this.snackBar.open(`파일은 최대 10개를 넘을 수 없습니다. `, "확인", {
        duration: 2000,
      })
    }else{
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        if(file.size/1024/1024 > this.maxFileSize){
          this.snackBar.open(`파일 하나의 크기는 ${this.maxFileSize}MB를 넘을 수 없습니다. `, "확인", {
            duration: 2000,
          })
          exist = true
          return
        }
      }
      if(!exist){
        this.selectedFiles = files
      }
    }
  }

  removeMember(id){
    this.selectedMembers = _.filter(this.selectedMembers, function(member){
      return member.id != id
    })
  }

  removeFile(fileName){
    this.selectedFiles = _.filter(this.selectedFiles, function(file){
      return file.name != fileName
    })
  }

  goList(){
    this.router.navigate(['/board-content'], { queryParams: { id: this.boardId }})
  }

  mod(){
    //수정
    this.router.navigate(['/board-content', this.idx], { queryParams: { mode: 'modify', boardId:this.boardId } })
  }

  answer(){//답변으로 이동
    this.answerMode = "answer"
    this.form2.controls.status.enable()
  }

  answerReg(){//답변저장
  
    if(this.answerIdx){
      this.api.modifyAnswer(this.boardId,this.idx,this.answerIdx, this.form2.value).subscribe(response=>{
        if(response){
          this.router.navigate(['/board-content', this.idx], { queryParams: { mode: 'info', boardId: this.boardId } })
        }
      })  
    }else{
      this.api.createAnswer(this.boardId,this.idx, this.form2.value).subscribe(response=>{
        if(response){
          this.router.navigate(['/board-content', this.idx], { queryParams: { mode: 'info', boardId: this.boardId } })
        }
        
      })
    }

  }
  del(){
    const dialogRef = this.dialog.open(DefaultDialogComponent)
    dialogRef.componentInstance.data = { content: '삭제 하시겠습니까?', activeText: '삭제', closeText: '취소' }
    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        this.api.delBoardContent(this.boardId,this.idx).subscribe(response => {
          const dialogRef = this.dialog.open(DefaultDialogComponent)
          dialogRef.componentInstance.data = { content: '삭제되었습니다.', closeText: '확인' }
          dialogRef.afterClosed().subscribe(response => {
            this.goList()
          })
        })
      }
    })
  }

  prev(){
    this.location.back()
  }

  async save(){
    if(!this.form1.value.agree){
      let errText=this.mode=='reg'? '등록':'수정'
      this.snackBar.open(`약관에 동의해야만 게시글을 ${errText} 할 수 있습니다.`, "확인", {
        duration: 2000,
      })
      return
    }
    let fileKeys=[]
    for (let file of this.selectedFiles) {
      if(file.key){
        fileKeys.push(file.key)
      }else{
        let response = await this.api.uploadFile(file,"board")
        console.log(response)
        fileKeys.push(response.key)
      }

    }
    let vo:any = {
      is_notice: this.form1.value.notice,
      is_secret: this.form1.value.secret,
      title: this.form1.value.title,
      content: this.form1.value.content,
      category_code: this.form1.value.category.length>0? this.form1.value.category:undefined,
      dynamic_data: {
        name: this.form1.value.name,
        secret_num: this.form1.value.pw,
        phone: this.form1.value.phone,
        email: this.form1.value.email,
        related_users: _.map(this.selectedMembers,(item)=>{
          return item.id
        }),
        star_point: this.form1.value.star,
        link: this.form1.value.link,
        attach_file_keys: fileKeys,
        additional_fields:[]
      },
      terms_agree: this.form1.value.agree
    }
    if(this.fieldList.length>0){
      for(let field of this.fieldList){
        vo.dynamic_data.additional_fields.push({title:field.title,content:this.form1.value[field.name]})
      }
    }

    // console.log(vo)
    if(this.mode =='reg'){
    this.api.createBoardContent(this.boardId, vo).subscribe(response => {
      if(response){
        const dialogRef = this.dialog.open(DefaultDialogComponent)
        dialogRef.componentInstance.data = { content: '등록되었습니다.', closeText: '확인' }
        dialogRef.afterClosed().subscribe(response => {
          this.router.navigate(['/board-content'], {queryParams: {id:this.boardId}})
        })
      }
    })
    }else if(this.mode =='modify'){
    if(vo.dynamic_data.secret_num.length==0){
      delete vo.dynamic_data.secret_num
    }
    this.api.modifyBoardContent(this.boardId,this.idx, vo).subscribe(response => {
      if(response){
        const dialogRef = this.dialog.open(DefaultDialogComponent)
        dialogRef.componentInstance.data = { content: '수정되었습니다.', closeText: '확인' }
        dialogRef.afterClosed().subscribe(response => {
          this.router.navigate(['/board-content'], {queryParams: {id:this.boardId}})
        })
      }
    })
    }
  }

  recommend(){

  }

  nonRecommend(){

  }

  regReply(replyData = undefined){
    let vo:any = {}
    if(replyData){
      replyData.toggle = false
      vo.target_reply_idx = replyData.idx
      vo.content = replyData.form.value.content
    }else{
      vo.content = this.form3.value.content
    }

    if(!this.global.isLogOn){
      vo.creator_name=this.form3.value.creator_name
      vo.secret_num=this.form3.value.password
    }
    vo.secret=this.form3.value.secret
    this.api.createReply(this.idx,vo).subscribe(response=>{
      this.replies=[]
      this.getReplies()
    })
  }

  modifyReply(reply){
    let vo:any={}
    reply.toggle = false
    reply.modify=false
    vo.content = reply.form.value.content
    vo.secret = reply.form.value.secret
    this.api.modifyReply(this.idx,reply.idx,vo,reply.form.value.password).subscribe(response=>{
      this.replies=[]
      this.getReplies()
    })
  }

  delReply(idx,secret_num=undefined){
    const dialogRef = this.dialog.open(DefaultDialogComponent)
    dialogRef.componentInstance.data = { content: '삭제 하시겠습니까?', activeText: '삭제', closeText: '취소' }
    dialogRef.afterClosed().subscribe(response => {
      if(response){
        this.api.delReply(this.idx,idx,secret_num).subscribe(response=>{
          this.replies=[]
          this.getReplies()
        })
      }
    })
  }

  replyReply(idx){

  }

  marginBottom(){
    return this.global.isLogOn == true ? 'mb-16' : ''
  }
  scroll(id) {
    let el = document.getElementById(id);
    el.scrollIntoView();
  }
}
