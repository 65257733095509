import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { DefaultDialogComponent } from 'app/dialogs/default-dialog/default-dialog.component';
import { APIService } from 'app/services/api.service';

@Component({
  selector: 'app-email-management',
  templateUrl: './email-management.component.html',
  styleUrls: ['./email-management.component.scss']
})
export class EmailManagementComponent implements OnInit {
  type
  selectList: Array<any> = [{ display: '네이버 클라우드', value: 'naver_cloud' }, { display: '메일 건', value: 'mailgun' }]
  formList: Array<any> = []
  data: any = {}
  constructor(public api: APIService,
    public dialog: MatDialog,
    // private global: Globals,
    // private route: ActivatedRoute,
    // private snackBar: MatSnackBar,
    // private iconRegistry: MatIconRegistry,
    // private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    // private router: Router
  ) { }

  ngOnInit() {
    this.getMailForm()
  }


  setInitServiceForm() {
    let setData
    switch (this.type) {
      case "naver_cloud":
        setData = {
          access_key: '',
          secret_key: '',
          from_email: '',
          to_email: ''
        }
        break;
      case "mail_gun":
        setData = {}
        break;
    }
    this.data = setData
    this.setFormList()
  }
  setFormList() {
    let setFormList
    switch (this.type) {
      case "naver_cloud":
        setFormList = [{
          title: '네이버 access key',
          value: 'access_key'
        }, {
          title: '네이버 secret key',
          value: 'secret_key'
        }, {
          title: '발송자 이메일 주소',
          value: 'from_email'
        }, {
          title: '발송자 이름',
          value: 'from_name'
        }, {
          title: '수신자 메일 주소 - 여러개일 경우 , 로 구분',
          value: 'to_email'
        }]
        break;
      case "mail_gun":
        setFormList = []
        break;
    }
    this.formList = setFormList
  }
  getMailForm() {
    this.api.getEmailSetting().subscribe(response => {
      if (response) {
        this.type = response.mail_service
        this.data = response.settings
        this.setFormList()
      }
    })
  }
  save() {
    let vo = {
      mail_service: this.type,
      settings:this.data
    }
    switch (this.type) {
      case "naver_cloud":
        if(vo.settings.to_email instanceof Array){
        }else{
          vo.settings.to_email=vo.settings.to_email.trim()
          vo.settings.to_email=vo.settings.to_email.split(',')
        }
        
        break;
      case "mail_gun":
        break;
    }
    this.api.setEmailSetting(vo).subscribe(response=>{
      const dialogRef = this.dialog.open(DefaultDialogComponent)
      dialogRef.componentInstance.data = { content: '저장되었습니다.', closeText: '확인' }
    })

  }
}
