import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Globals } from './globals';
import { Environment } from 'app/Environment';
import { Observable } from 'rxjs';
import { User } from 'app/models/user';
import { DefaultImgSrcDirective } from '@angular/flex-layout';
import { BoardResponse, DefaultResponse, UserResponse, AccessLogResponse, DefaultPageResponse, BannerResponse, NormalBannerResponse, SideBannerResponse } from 'app/models/response';
import { stat } from 'fs';
import { Banner } from 'app/models/banner';
import { NormalBanner } from 'app/models/normal-banner';
import { SideBanner } from 'app/models/side-banner';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}
@Injectable({
  providedIn: 'root'
})
export class APIService {

  private apiUrl

  constructor(private http: HttpClient,
    public global: Globals) {
    this.apiUrl = Environment.BACKEND_HOST + '/api/v1'
  }

  private log(message: string) {

  }

  login(id: string, pw: string) {
    let vo = { id: id, password: pw }
    return this.http.post<any>(`${this.apiUrl}/auth`, vo, httpOptions);
  }

  getUserSetting() {
    return this.http.get<any>(`${this.apiUrl}/site-settings/user`, { headers: httpOptions.headers })
  }

  setUserSetting(body) {
    return this.http.put<any>(`${this.apiUrl}/site-settings/user`, body, { headers: httpOptions.headers })
  }

  getSEOSetting() {
    return this.http.get<any>(`${this.apiUrl}/site-settings/seo`, { headers: httpOptions.headers })
  }

  setSEOSetting(body) {
    return this.http.put<any>(`${this.apiUrl}/site-settings/seo`, body, { headers: httpOptions.headers })
  }

  getEmailSetting() {
    return this.http.get<any>(`${this.apiUrl}/site-settings/email`, { headers: httpOptions.headers })
  }

  setEmailSetting(body) {
    return this.http.put<any>(`${this.apiUrl}/site-settings/email`, body, { headers: httpOptions.headers })
  }


  modifySitemap(sitemapSetting) {
    return this.http.put<any>(`${this.apiUrl}/site-settings/sitemap`, sitemapSetting, { headers: httpOptions.headers })
  }

  getGrades(overOperator = true) {
    let params = new HttpParams().set("with_read_only", `${overOperator}`)
    return this.http.get<any>(`${this.apiUrl}/memberships`, { headers: httpOptions.headers, params: params })
  }

  getGradesAsync(overOperator = true) {
    let params = new HttpParams().set("with_read_only", `${overOperator}`)
    return this.http.get(`${this.apiUrl}/memberships`, { headers: httpOptions.headers, params: params }).toPromise()
  }

  regGrade(body) {
    return this.http.post<any>(`${this.apiUrl}/memberships`, body, { headers: httpOptions.headers })
  }

  delGrade(code) {
    return this.http.delete<any>(`${this.apiUrl}/memberships/${code}`, { headers: httpOptions.headers })
  }

  async uploadFile(file: File, category: string): Promise<any> {
    const formData = new FormData();
    formData.append('name', file.name)
    formData.append('file', file)
    return await this.http.post<any>(`${this.apiUrl}/files/${category}`, formData).toPromise()
  }

  regUser(body: any) {
    console.log(`${this.apiUrl}/users`)
    console.log(body)
    return this.http.post<any>(`${this.apiUrl}/users`, body, { headers: httpOptions.headers })
  }

  modifyUser(id: string, body: any) {
    return this.http.put<any>(`${this.apiUrl}/users/${id}`, body, { headers: httpOptions.headers })
  }

  createBanner(vo: any) {
    return this.http.post<Banner>(`${this.apiUrl}/banners`, vo, { headers: httpOptions.headers })
  }

  getBannerList() {
    return this.http.get<BannerResponse>(`${this.apiUrl}/banners`, { headers: httpOptions.headers })
  }

  getBannerDetail(idx: number) {
    return this.http.get<Banner>(`${this.apiUrl}/banners/${idx}`, { headers: httpOptions.headers })
  }

  modifyBanner(idx: number, vo: any) {
    return this.http.put<DefaultResponse>(`${this.apiUrl}/banners/${idx}`, vo, { headers: httpOptions.headers })
  }
  deleteBanner(idx: number) {
    return this.http.delete<DefaultResponse>(`${this.apiUrl}/banners/${idx}`, { headers: httpOptions.headers })
  }

  createNormalBanner(vo: any) {
    return this.http.post<NormalBanner>(`${this.apiUrl}/normal-banners`, vo, { headers: httpOptions.headers })
  }

  getNormalBannerList(page: number, size: number = 10) {
    let params = new HttpParams().set("page", page + "")
    params = params.append("size", size + "")
    return this.http.get<NormalBannerResponse>(`${this.apiUrl}/normal-banners`, { headers: httpOptions.headers, params: params })
  }

  getNormalBannerDetail(idx: number) {
    return this.http.get<NormalBanner>(`${this.apiUrl}/normal-banners/${idx}`, { headers: httpOptions.headers })
  }

  modifyNormalBanner(idx: number, vo: any) {
    return this.http.put<DefaultResponse>(`${this.apiUrl}/normal-banners/${idx}`, vo, { headers: httpOptions.headers })
  }
  deleteNormalBanner(idx: number) {
    return this.http.delete<DefaultResponse>(`${this.apiUrl}/normal-banners/${idx}`, { headers: httpOptions.headers })
  }

  createSideBanner(vo: any) {
    return this.http.post<SideBanner>(`${this.apiUrl}/side-banners`, vo, { headers: httpOptions.headers })
  }

  getSideBannerList(page: number, size: number = 10) {
    let params = new HttpParams().set("page", page + "")
    params = params.append("size", size + "")
    return this.http.get<SideBannerResponse>(`${this.apiUrl}/side-banners`, { headers: httpOptions.headers, params: params })
  }

  getSideBannerDetail(idx: number) {
    return this.http.get<SideBanner>(`${this.apiUrl}/side-banners/${idx}`, { headers: httpOptions.headers })
  }

  modifySideBanner(idx: number, vo: any) {
    return this.http.put<DefaultResponse>(`${this.apiUrl}/side-banners/${idx}`, vo, { headers: httpOptions.headers })
  }
  deleteSideBanner(idx: number) {
    return this.http.delete<DefaultResponse>(`${this.apiUrl}/side-banners/${idx}`, { headers: httpOptions.headers })
  }

  getUsers(page: number = 1, size: number = 10, search_type: string = "", search_keyword: string = "", auth: Array<string> = []) {
    let params = new HttpParams().set("page", `${page}`)
    params = params.append("size", size + "")
    if (auth.length > 0) {
      params = params.append("auth", auth.join(','))
    }
    if (search_type.length > 0) {
      params = params.append("search_type", search_type)
    }
    if (search_keyword.length > 0) {
      params = params.append("search_keyword", search_keyword)
    }
    return this.http.get<any>(`${this.apiUrl}/users`, { headers: httpOptions.headers, params: params })
  }

  getUser(id) {
    return this.http.get<any>(`${this.apiUrl}/users/${id}`, { headers: httpOptions.headers })
  }

  modifyPassword(id, body) {
    return this.http.put<any>(`${this.apiUrl}/users/${id}/password`, body, { headers: httpOptions.headers })
  }

  getTerms() {
    return this.http.get<any>(`${this.apiUrl}/terms`, { headers: httpOptions.headers })
  }

  getTermCategory(category: string) {
    return this.http.get<any>(`${this.apiUrl}/terms/${category}`, { headers: httpOptions.headers })
  }

  setTerms(body) {
    return this.http.put<any>(`${this.apiUrl}/terms`, body, { headers: httpOptions.headers })
  }

  regPopup(body) {
    return this.http.post<any>(`${this.apiUrl}/popup-layers`, body, { headers: httpOptions.headers })
  }

  getPopups(page: number = 1, size: number = 10) {
    let params = new HttpParams().set("page", `${page}`)
    params = params.append("size", size + "")

    return this.http.get<any>(`${this.apiUrl}/popup-layers`, { headers: httpOptions.headers, params: params })
  }

  getPopup(idx) {
    return this.http.get<any>(`${this.apiUrl}/popup-layers/${idx}`, { headers: httpOptions.headers })
  }

  modifyPopup(idx, body) {
    return this.http.put<any>(`${this.apiUrl}/popup-layers/${idx}`, body, { headers: httpOptions.headers })
  }

  delPopup(idx) {
    return this.http.delete<any>(`${this.apiUrl}/popup-layers/${idx}`, { headers: httpOptions.headers })
  }

  regBoard(body) {
    return this.http.post<any>(`${this.apiUrl}/boards`, body, { headers: httpOptions.headers })
  }

  getBoards(page: number = 1, size: number = 10, search_type: Array<any> = [], search_keyword: string = '') {
    let params = new HttpParams().set("page", `${page}`)
    params = params.append("size", size + "")
    if (search_type.length > 0) {
      params = params.append("search_type", search_type + "")
    }
    if (search_keyword.length > 0) {
      params = params.append("search_keyword", search_keyword)
    }
    return this.http.get<any>(`${this.apiUrl}/boards`, { headers: httpOptions.headers, params: params })
  }

  getBoardDetail(board_id: string) {
    return this.http.get<any>(`${this.apiUrl}/boards/${board_id}`, { headers: httpOptions.headers })
  }

  getBoard(id) {
    return this.http.get<any>(`${this.apiUrl}/boards/${id}`, { headers: httpOptions.headers })
  }

  getBoardCategories(id) {
    return this.http.get<any>(`${this.apiUrl}/boards/${id}/categories`, { headers: httpOptions.headers })
  }

  modifyBoard(id, body) {
    return this.http.put<any>(`${this.apiUrl}/boards/${id}`, body, { headers: httpOptions.headers })
  }

  delBoard(board_id: string) {
    return this.http.delete<any>(`${this.apiUrl}/boards/${board_id}`, { headers: httpOptions.headers })
  }

  boardDetail(id, errorHandle = "no") {
    return this.http.get<any>(`${this.apiUrl}/boards/${id}`, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Error-Handle': errorHandle }) })
  }

  regBoardCategory(boardID, body) {
    return this.http.post<any>(`${this.apiUrl}/boards/${boardID}/categories`, body, { headers: httpOptions.headers })
  }

  createBoardContent(id: string, vo: any) {
    return this.http.post<any>(`${this.apiUrl}/boards/${id}/contents`, vo, { headers: httpOptions.headers })
  }

  getBoardContents(id: string, page: number = 1, size: number = 10, search_type: string = '', search_keyword: string = '', sort: string = undefined) {
    let params = new HttpParams().set("page", `${page}`)
    params = params.append("size", size + "")
    if (search_type.length > 0) {
      params = params.append("search_type", search_type + "")
    }
    if (search_keyword.length > 0) {
      params = params.append("search_keyword", search_keyword)
    }
    if (sort) {
      params = params.append('sort', sort)
    }
    return this.http.get<any>(`${this.apiUrl}/boards/${id}/contents`, { headers: httpOptions.headers, params: params })
  }

  boardContentDetail(boardId, contentIdx) {
    return this.http.get<any>(`${this.apiUrl}/boards/${boardId}/contents/${contentIdx}`, { headers: httpOptions.headers })
  }

  delBoardContent(boardId, contentIdx) {
    return this.http.delete<any>(`${this.apiUrl}/boards/${boardId}/contents/${contentIdx}`, { headers: httpOptions.headers })
  }
  delBoardContents(boardId, idxList: Array<any> = []) {
    let params = new HttpParams().set("idx_list", `${idxList}`)
    return this.http.delete<any>(`${this.apiUrl}/boards/${boardId}/contents`, { headers: httpOptions.headers, params: params })
  }

  delBoardCategory(boardID, code) {
    return this.http.delete<any>(`${this.apiUrl}/boards/${boardID}/categories/${code}`, { headers: httpOptions.headers })
  }

  modifyBoardContent(id: string, idx: string, vo: any) {
    return this.http.put<any>(`${this.apiUrl}/boards/${id}/contents/${idx}`, vo, { headers: httpOptions.headers })
  }
  createAnswer(id: string, contentIdx: string, vo: any) {
    return this.http.post<any>(`${this.apiUrl}/boards/${id}/contents/${contentIdx}/answers`, vo, { headers: httpOptions.headers })
  }

  getAnswer(id: string, contentIdx: string) {
    return this.http.get<any>(`${this.apiUrl}/boards/${id}/contents/${contentIdx}/answers`, { headers: httpOptions.headers })
  }

  createReply(idx, vo) {
    return this.http.post<any>(`${this.apiUrl}/contents/${idx}/replies`, vo, { headers: httpOptions.headers })
  }

  getReplies(idx, page, size = 10) {
    let params = new HttpParams().set("page", `${page}`)
    params = params.append("size", size + "")
    return this.http.get<any>(`${this.apiUrl}/contents/${idx}/replies`, { headers: httpOptions.headers, params: params })
  }
  modifyReply(content_idx, idx, vo, secret_num = undefined) {
    let params = new HttpParams()
    if (secret_num) {
      params.append('secret_num', secret_num)
    }
    return this.http.put<any>(`${this.apiUrl}/contents/${content_idx}/replies/${idx}`, vo, { headers: httpOptions.headers, params: params })
  }
  delReply(content_idx, idx, secret_num = undefined) {
    let params = new HttpParams()
    if (secret_num) {
      params.append('secret_num', secret_num)
    }
    return this.http.delete<any>(`${this.apiUrl}/contents/${content_idx}/replies/${idx}`, { headers: httpOptions.headers, params: params })
  }
  modifyAnswer(id: string, contentIdx: string, answerIdx: string, vo: any) {
    return this.http.put<any>(`${this.apiUrl}/boards/${id}/contents/${contentIdx}/answers/${answerIdx}`, vo, { headers: httpOptions.headers })
  }

  modifyContentVisible(contentIdx: string, vo: any) {
    return this.http.put<any>(`${this.apiUrl}/contents/${contentIdx}/visible`, vo, { headers: httpOptions.headers })
  }
  getTemplateList(page: number, size: number = 10, type = undefined, target = undefined, search_type = undefined, search_keyword = undefined) {
    let params = new HttpParams().set("page", `${page}`)
    params = params.append("size", size + "")
    if (type) {
      params = params.append("type", type)
    }
    if (target) {
      params = params.append("target", target)
    }
    if (search_type && search_keyword) {
      params = params.append("search_type", search_type + "")
      params = params.append("search_keyword", search_keyword + "")
    }
    return this.http.get<any>(`${this.apiUrl}/email-templates`, { headers: httpOptions.headers, params: params })
  }

  createTemplate(vo: any) {
    return this.http.post<any>(`${this.apiUrl}/email-templates`, vo, { headers: httpOptions.headers })
  }

  getTemplateDetail(idx: number) {
    return this.http.get<any>(`${this.apiUrl}/email-templates/${idx}`, { headers: httpOptions.headers })
  }

  modifyTemplate(idx: number, vo: any) {
    return this.http.put<any>(`${this.apiUrl}/email-templates/${idx}`, vo, { headers: httpOptions.headers })
  }

  delTemplate(idx: number) {
    return this.http.delete<any>(`${this.apiUrl}/email-templates/${idx}`, { headers: httpOptions.headers })
  }
}