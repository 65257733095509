import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { MatDialogRef, ErrorStateMatcher } from '@angular/material';
import { APIService } from 'app/services/api.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
      return control.parent.errors && control.parent.errors['notSame']
  }
}
@Component({
  selector: 'app-reg-member',
  templateUrl: './reg-member.component.html',
  styleUrls: ['./reg-member.component.scss']
})
export class RegMemberComponent implements OnInit {
  form: FormGroup
  profileFile
  errorMessage
  pwerrorMessage
  message
  idExistCheck
  matcher = new MyErrorStateMatcher();
  constructor(
    public api: APIService,
    private _formBuiler: FormBuilder,
    private dialog: MatDialogRef<RegMemberComponent>
  ) { }

  ngOnInit() {

    this.form = this._formBuiler.group({
      name: ['', [Validators.required]],
      id: ['', [Validators.required]],
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
      email: ['', [Validators.required]],
      auth: ['USER'],
      status: [true]
    },{
      validator:this.checkPasswords
    })
  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
  let pass = group.get('password').value;
  let confirmPass = group.get('confirmPassword').value;
  return pass === confirmPass ? null : { notSame: true }     
}
  submit() {
    this.form.markAllAsTouched();
    if (this.form.value.name < 1) {
      this.errorMessage = '이름을 입력해주세요.'
    } else if (!this.idExistCheck) {
      this.errorMessage = '아이디 중복체크를 해주세요.'
    }
    else {
      if (this.form.valid) {
        // this.api.createMember(this.form.value).subscribe(response => {
        //   if(response.success){
        //     this.dialog.close()
        //   }
        // })
      }
    }
  }

  idExist() {
    // this.api.readMemberDetail(this.form.value.id).subscribe(response=>{
    //   if(response){
    //     this.message = '아이디가 중복됩니다.'
    //   }else{
    //     this.message = '사용 가능합니다.'
    //     this.errorMessage = ''
    //     this.idExistCheck = true
    //   }
    // })
  }

  idChanged() {
    this.idExistCheck = false
    this.message = '아이디 중복체크를 해주세요.'
  }
}
