import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-preview-dialog',
  templateUrl: './preview-dialog.component.html',
  styleUrls: ['./preview-dialog.component.scss']
})
export class PreviewDialogComponent implements OnInit {
  @Input() data:{
    content:any
  }
  constructor(
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.data.content = this.sanitizer.bypassSecurityTrustHtml(this.data.content)
  }

}
