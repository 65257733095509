import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { Globals } from 'app/services/globals';
import { SelectionModel } from '@angular/cdk/collections';
import * as _ from 'underscore';
import { APIService } from 'app/services/api.service';

@Component({
  selector: 'app-select-member-dialog',
  templateUrl: './select-member-dialog.component.html',
  styleUrls: ['./select-member-dialog.component.scss']
})
export class SelectMemberDialogComponent implements OnInit {
  selection = new SelectionModel<any>(true, []);
  displayedColumns: string[] = ['1', '2', '3', '4', '5']
  dataSource: any
  totalCnt
  size: number = 5
  currentPage: number = 1

  @Input() data:{
    members: any
  }

  constructor(
    public api: APIService,
    private formBuiler: FormBuilder,
    private dlg: MatDialog,
    private snackBar: MatSnackBar,
    public global: Globals,
    private dialog:MatDialogRef<SelectMemberDialogComponent>) {
  }

  ngOnInit() {
    this.getList()
  }

  getList(){
    this.api.getUsers(this.currentPage,this.size).subscribe(response=>{
      this.dataSource=response.data
      this.totalCnt=response.pagination.total_cnt
    })
    this.totalCnt = 10
  }

  onPaginateChange(event) {
    this.currentPage = event.pageIndex + 1
    this.getList()
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.forEach(row => this.selection.select(row));
  }
  
  choice(){
    if(this.selection.selected && this.selection.selected.length > 0){
      if(this.data.members && this.data.members.length > 0){
        let isExist = false
        this.data.members.forEach(element => {
          if(_.find(this.selection.selected, function(data){ return data.id == element.id; })){
            isExist = true
          }
        })
        if(isExist){
          this.snackBar.open("이미 등록된 회원입니다.", "확인", {
            duration: 2000,
          })
        }else{
          this.dialog.close(this.selection.selected)
        }
      }else{
        this.dialog.close(this.selection.selected)
      }
    }else{
      this.snackBar.open("회원을 선택해주세요", "확인", {
        duration: 2000,
      })
    }
  }
}