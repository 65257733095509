import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { APIService } from 'app/services/api.service';
import { MatDialog, MatSnackBar, MatIconRegistry, NativeDateAdapter, DateAdapter } from '@angular/material';
import { Globals } from 'app/services/globals';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import * as _moment from 'moment';
import * as _ from 'underscore';
import { FroalaOptions } from 'app/models/froala-options';
import { DefaultDialogComponent } from 'app/dialogs/default-dialog/default-dialog.component';

const moment = _moment
export const MY_FORMATS = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' }
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  },
};

export class MyDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: Object): string {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    return year + '-' + this._to2digit(month) + '-' + this._to2digit(day)
  }

  private _to2digit(n: number) {
    return ('00' + n).slice(-2);
  }
}

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MyDateAdapter },
    { provide: MY_FORMATS, useValue: MY_FORMATS },
  ],
})
export class TermsComponent implements OnInit {
  formList: Array<any> = []
  openCreateTerm: boolean = false
  new_term: FormGroup = this.formBuilder.group({
    title: "",
    category: "",
    use: false
  })
  editorOptions: Object

  constructor(public api: APIService,
    public dialog: MatDialog,
    private global: Globals,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private ratingConfig: NgbRatingConfig,
    private router: Router) {

    var token = localStorage.getItem('id_token')
    if (token != null && token != undefined && token.length > 0) {
      this.editorOptions = new FroalaOptions(token, "/api/v1/files/image", this.global, 250)
    } else {
      this.editorOptions = {
        attribution: false,
        language: 'ko',
        height: 250,
        charCounterCount: false,
        videoResponsive: true,
        placeholderText: "이미지는 업로드 할 수 없습니다. (토큰없음)",
        key: global.froalaKey,
      }
    }
  }

  ngOnInit() {
    this.getTerms()
  }

  getTerms() {
    this.api.getTerms().subscribe(response => {
      for (let form of response) {
        let newForm = {}
        for (let key in form) {
          switch (key) {
            case "category":
            case "content":
            case "title":
            case "version":
              newForm[key] = form[key]
              break;
            case "mandatory":
            case "use":
              newForm[key] = form[key]
              break;
            case "publish_date":
              newForm[key] = moment(form[key]).format('YYYY-MM-DD')
              break;
            case "officer":
              for (let innerKey in form[key]) {
                newForm[innerKey] = form[key][innerKey]
              }
              break;
          }
        }
        this.formList.push(this.formBuilder.group(newForm))
      }
    })
  }

  save() {
    let body = []
    for (let form of this.formList) {
      console.log(form.value)
      let vo:any = {}
      for (let key in form.value) {
          vo[key] = form.value[key]
      }
      vo.officer = {
        name: form.value.name,
        nick: form.value.nick,
        division: form.value.division,
        phone: form.value.phone,
        email: form.value.email
      }
      body.push(vo)
    }
    this.api.setTerms(body).subscribe(response => {
      const dialogRef = this.dialog.open(DefaultDialogComponent)
      dialogRef.componentInstance.data = { content: '저장되었습니다.', closeText: '확인' }
    })
  }

  saveNewForm() {
    let titleCheck = _.find(this.formList, (item) => {
      return this.new_term.value.title == item.value.title
    })
    if (titleCheck) {
      let dialogRef = this.dialog.open(DefaultDialogComponent)
      dialogRef.componentInstance.data = { content: '이미 존재하는 약관명입니다.', closeText: '확인' }
      return
    }
    let categoryCheck = _.find(this.formList, (item) => {
      return this.new_term.value.category == item.value.category
    })
    if (categoryCheck) {
      let dialogRef = this.dialog.open(DefaultDialogComponent)
      dialogRef.componentInstance.data = { content: '이미 존재하는 카테고리명입니다.', closeText: '확인' }
      return
    }
    let vo: any = {
      title: this.new_term.value.title,
      category: this.new_term.value.category,
      content: '',
      version: 1,
      mandatory: false,
      use: true,
      publish_date: '',
    }
    if (this.new_term.value.use) {
      vo.name = '',
        vo.nick = '',
        vo.divison = '',
        vo.phone = '',
        vo.email = ''
    }
    this.formList.push(this.formBuilder.group(vo))
    this.openCreateTerm = false

  }

  removeForm(index: number) {
    this.formList.splice(index, 1)
  }
}
