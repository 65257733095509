import { Environment } from "../Environment";
import { APIService } from 'app/services/api.service';

export class UploadAdapter {
  private loader;
  api: APIService
  category: string
  forS3: any
  constructor(loader: any, thisApi: APIService, category: string) {
    this.loader = loader;
    this.api = thisApi
    this.category = category
  }

  public upload(): Promise<any> {
    return this.readThis(this.loader.file);
  }

  async readThis(file: File): Promise<any> {
    let f= await file
    let imagePromise: Promise<any> = new Promise((resolve, reject) => {
      var myReader: FileReader = new FileReader();
      // myReader.addEventListener('loadend',(ev) => {
      //   console.log('myReader.addEventListener!!', ev)
      // })
      myReader.onloadend = async (e) => {
        let response = await this.api.uploadFile(f, this.category)
        resolve({ default: response.url });
      }
      myReader.readAsDataURL(f);
    });
    return imagePromise;
  }
}