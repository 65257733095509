import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { APIService } from 'app/services/api.service';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { DefaultDialogComponent } from 'app/dialogs/default-dialog/default-dialog.component';

@Component({
  selector: 'app-popup-layer-management',
  templateUrl: './popup-layer-management.component.html',
  styleUrls: ['./popup-layer-management.component.scss']
})
export class PopupLayerManagementComponent implements OnInit {
  displayedColumns: string[] = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11']
  dataSource: any
  totalCnt
  size: number = 10
  currentPage: number = 1
  form: FormGroup = this.formBuilder.group({
    title: '',
    type: '',
    start: '',
    end: '',
    left: 0,
    top: 0,
    width: 0,
    height: 0,
    created_at: '',
  })
  
  constructor(
    public api: APIService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private router: Router,
  ) { }

  ngOnInit() {
    this.getList()
  }

  reg(){
    this.router.navigate(['/popup', 'reg-popup-layer'], { queryParams: { mode: 'reg' } })
  }

  showInfo(data){
    this.router.navigate(['/popup', data.idx], { queryParams: { mode: 'info' } })
  }

  del(data){
    const dialogRef = this.dialog.open(DefaultDialogComponent)
    dialogRef.componentInstance.data = { content: '삭제 하시겠습니까?', activeText: '삭제', closeText: '취소' }
    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        this.api.delPopup(data.idx).subscribe(response => {
          if (response) {
            this.refreshList()
          }
        })
      }
    })
  }

  refreshList(){
    this.currentPage = 1
    this.dataSource = []
    this.totalCnt = 0

    this.getList()
  }

  getList() {
    this.api.getPopups(this.currentPage, this.size).subscribe(response => {
      this.dataSource = response.data
      this.totalCnt = response.pagination.total_cnt
    })
  }

  search() {
    if(this.form.valid){
      console.log(this.form.value)
      this.getList()
    }
  }

  onPaginateChange(event) {
    this.currentPage = event.pageIndex + 1
    this.getList()
  }
}

